import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import { Card } from "@nextui-org/react";

//import "swiper/css";

import { Autoplay } from "swiper";

const sliderData = [
  { title: "Completed", change: "blue", number: "5" },
  { title: "On-going", change: "secondary", number: "3" },
  { title: "Canceled", change: "pink", number: "2" },
  // {title:'Rescheduled', change:'black', number:'720'},
];

export default function TicketingSlider({
  totalOngoing,
  totalOngoingLoading,
  maturedInfo,
  maturedInfoLoading,
  preMaturedInfo,
  preMaturedInfoLoading,
}) {
  return (
    <div className="w-full flex flex-row space-x-3">
      <Card className="p-6 mb-6 flex-grow">
        <h4>Total Ongoing</h4>
        {totalOngoingLoading ? (
          <Skeleton width={100} height={50} />
        ) : (
          <h2>{Number(totalOngoing).toFixed(4)}</h2>
        )}
      </Card>
      <Card className="p-6 mb-6 flex-grow">
        <h4>Total Matured</h4>
        {maturedInfoLoading ? (
          <Skeleton width={100} height={50} />
        ) : (
          <h2>{Number(maturedInfo?.total_staked_matured).toFixed(4)}</h2>
        )}
      </Card>
      <Card className="p-6 mb-6 flex-grow">
        <h4>Total Pre-Matured</h4>
        {preMaturedInfoLoading ? (
          <Skeleton width={100} height={50} />
        ) : (
          <h2>{Number(preMaturedInfo?.total_count).toFixed(4)}</h2>
        )}
      </Card>
    </div>
  );
}
