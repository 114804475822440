import axiosInstance from "./AxiosInstance";

export default class AuthService {
  static async requestPasswordReset({
    username,
    emailOrPhone,
    skipErrorRedirect = true,
  }) {
    const isEmail = emailOrPhone.includes("@");
    const type = isEmail ? "email" : "contact";
    let params = { username, type };

    if (isEmail) {
      params = { ...params, email: emailOrPhone };
    } else {
      emailOrPhone = emailOrPhone.replace(/\D/g, "").replace(/\s/, "");

      // add leading 0 if phone number is not in the format 0XXXXXXXXX
      if (emailOrPhone.length === 10) {
        emailOrPhone = `0${emailOrPhone}`;
      }

      params = { ...params, contact: emailOrPhone };
    }

    const resetResponse = await axiosInstance.post(
      "/request-password-reset",
      params,
      { skipErrorRedirect },
    );

    return resetResponse.data;
  }

  static async verifyPasswordReset({
    otp_request_token,
    otp_code,
    new_password,
    new_password_confirmation,
  }) {
    const resetResponse = await axiosInstance.post("/verify-password-reset", {
      otp_request_token,
      otp_code,
      new_password,
      new_password_confirmation,
    });

    return resetResponse.data;
  }
}
