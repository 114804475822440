import KycService from "../../../services/KycService";
import { useQuery } from "@tanstack/react-query";
import KycReviewTable from "./KycReviewTable";
import { Spinner, Chip } from "@nextui-org/react";

export default function KycReviewStatus() {
  const { data: myPendingResponse, isLoading: myPendingLoading } = useQuery({
    queryKey: ["kyc", "pending"],
    queryFn: () => KycService.getMyPending(),
    refetchInterval: 10000,
  });

  const { data: myApprovedResponse, isLoading: myApprovedLoading } = useQuery({
    queryKey: ["kyc", "approved"],
    queryFn: () => KycService.getMyApproved(),
    refetchInterval: 10000,
  });

  return (
    <>
      <span className="text-2xl font-bold text-white !mb-3">
        My Pending Applications
      </span>
      <div className="w-full flex items-center justify-center flex-col space-y-3 mt-3">
        <KycReviewTable
          data={myPendingResponse?.pending_kyc ?? []}
          loadingContent={<Spinner />}
          isLoading={myPendingLoading}
          type={"pending"}
        />
      </div>
      <span className="text-2xl font-bold text-white !mb-3">
        My Approved Applications
      </span>
      <div className="w-full flex items-start justify-center flex-col space-y-3 mt-3">
        <KycReviewTable
          data={myApprovedResponse?.approved_kyc ?? []}
          loadingContent={<Spinner />}
          isLoading={myApprovedLoading}
          type="approved"
        />
      </div>
    </>
  );
}
