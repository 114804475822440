import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header2 from "./Header2";
import ChatBox from "../ChatBox";
import { useQuery } from "@tanstack/react-query";
import UsersService from "../../../services/UsersService";

const NavBlog = ({
  title,
  onClick: ClickToAddEvent,
  onClick2,
  onClick3,
  userProfile,
  userProfileLoading,
}) => {
  const [toggle, setToggle] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);

  return (
    <Fragment>
      <NavHader />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      <Header2
        userProfile={userProfile}
        userProfileLoading={userProfileLoading}
      />
      <SideBar userProfile={userProfile} />
    </Fragment>
  );
};

export default NavBlog;
