import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";

export default function KycTable({
  data = [],
  bottomContent,
  loadingContent,
  isLoading,
  removeWrapper = false,
  onRowAction,
}) {
  const renderCell = (item, columnKey) => {
    switch (columnKey) {
      case "doc_type":
        return item[columnKey]
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1) + " ");

      case "time_added":
        return new Date(item[columnKey] * 1000).toLocaleString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      case "status":
        return (
          <Chip
            className="capitalize"
            color={
              item[columnKey] === "pending"
                ? "warning"
                : item[columnKey] === "approved"
                ? "success"
                : "danger"
            }
            size="sm"
            variant="flat"
          >
            {String(item[columnKey]).charAt(0).toUpperCase() +
              String(item[columnKey]).slice(1)}
          </Chip>
        );
      default:
        return item[columnKey] ?? "N/A";
    }
  };

  return (
    <Table
      bottomContent={bottomContent}
      isStriped
      fullWidth
      removeWrapper={removeWrapper}
      selectionMode="single"
      onRowAction={onRowAction}
      // bottomContent={bottomContent}
      className="overflow-scroll"
    >
      <TableHeader>
        <TableColumn key={"time_added"}>Time Added</TableColumn>
        <TableColumn key={"hash"}>Qr Hash</TableColumn>
        <TableColumn key={"doc_type"}>Document Type</TableColumn>
        {/* <TableColumn key={"doc_image"}>Image</TableColumn> */}
        <TableColumn key={"vip_code"}>VIP Code</TableColumn>
        <TableColumn key={"updated_by"}>Updated By</TableColumn>
        <TableColumn key={"time_approved"}>Time Approved</TableColumn>
        <TableColumn key={"time_denied"}>Time Denied</TableColumn>
        <TableColumn key={"status"}>Status</TableColumn>
      </TableHeader>
      <TableBody
        items={data ?? []}
        loadingContent={loadingContent}
        isLoading={isLoading}
        emptyContent="No data"
      >
        {(item) => (
          <TableRow key={item.hash} className="cursor-pointer">
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
