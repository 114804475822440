import axiosInstance from "./AxiosInstance";

export default class StakingService {
  static async getTotalOngoing() {
    const axiosResponse = await axiosInstance.get(`/staking/total-ongoing`);

    return axiosResponse.data;
  }

  static async getMaturedTotalInfo() {
    const axiosResponse = await axiosInstance.get(
      `/staking/matured-total-info`,
    );

    return axiosResponse.data;
  }

  static async getPreMaturedInfo() {
    const axiosResponse = await axiosInstance.get(`/staking/pre-matured-info`);

    return axiosResponse.data;
  }

  static async getOngoingList() {
    const axiosResponse = await axiosInstance.get(`/staking/ongoing-list`);

    return axiosResponse.data;
  }

  static async getMaturedList() {
    const axiosResponse = await axiosInstance.get(`/staking/matured-list`);

    return axiosResponse.data;
  }

  static async getPreMaturedList() {
    const axiosResponse = await axiosInstance.get(`/staking/pre-matured-list`);

    return axiosResponse.data;
  }
}
