import { keepPreviousData, useQuery } from "@tanstack/react-query";
import YachtService from "../../../services/YachtService";
import { useEffect, useState, useMemo } from "react";
import YachtCodesTable from "./YachtCodesTable";
import {
  Card,
  CardHeader,
  Pagination,
  useDisclosure,
  CardBody,
  Input,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Spinner,
} from "@nextui-org/react";
import YachtCodesModal from "./YachtCodesModal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import YachtCodesDeleteModal from "./YachtCodesDeleteModal";

export default function YachtCodes() {
  const [page, setPage] = useState(1);
  const [code, setCode] = useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onOpenChange: onDeleteOpenChange,
  } = useDisclosure();
  const [submitting, setSubmitting] = useState(false);

  const {
    data: codesList,
    isLoading: codesLoading,
    isPlaceholderData,
    refetch,
  } = useQuery({
    queryKey: ["yacht", "codes", page],
    queryFn: () => YachtService.getList({ page }),
    refetchInterval: 10000,
    placeholderData: keepPreviousData,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      code: "",
      reserved_for: "",
    },
  });

  const [stablePages, setStablePages] = useState(null);

  const pages = useMemo(() => {
    return codesList?.last_page;
  }, [codesList]);

  useEffect(() => {
    if (pages) {
      setStablePages(pages);
    }
  }, [pages]);

  const loadingState = isPlaceholderData || codesLoading ? "loading" : "idle";
  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onOpenChange: confirmOnOpenChange,
    onClose: confirmOnClose,
  } = useDisclosure();

  const onSubmit = () => {
    confirmOnOpen();
  };

  const submitData = (data, onClose) => {
    setSubmitting(true);

    toast
      .promise(YachtService.add(data), {
        pending: "Adding...",
        success: "Yacht code added successfully",
      })
      .then(() => {
        reset();
        refetch();
        confirmOnClose();
      })
      .catch((e) => {
        const errorMessage = e.response.data.description
          ? e.response.data.description
          : e.response.data.message
          ? e.response.data.message
          : "An error occurred";
        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
        onClose();
      });
  };

  const [deleting, setDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState();
  const onDelete = (code, onClose) => {
    setDeleting(true);

    toast
      .promise(YachtService.delete({ code }), {
        pending: "Deleting...",
        success: "Yacht code deleted successfully",
      })
      .then(() => {
        refetch();
        onClose();
      })
      .catch((e) => {
        const errorMessage = e.response.data.description
          ? e.response.data.description
          : e.response.data.message
          ? e.response.data.message
          : "An error occurred";
        toast.error(errorMessage);
        setDeleteError(errorMessage);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <>
      <span className="font-bold text-2xl text-white">Yacht Codes</span>
      <div className="w-full lg:w-2/3 mt-3 gap-3">
        <Card className="mb-3">
          <CardHeader>Add Yacht Code</CardHeader>
          <CardBody>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full flex flex-col sm:flex-row gap-3"
            >
              <Input
                {...register("code", {
                  required: "This Field is required",
                  minLength: {
                    value: 3,
                    message: "Requires 3 or more characters",
                  },
                })}
                label="Code"
                size="sm"
                isInvalid={errors.code}
                errorMessage={errors.code?.message}
              />
              <Input
                {...register("reserved_for", {
                  required: "This Field is required",
                  minLength: {
                    value: 3,
                    message: "Requires 3 or more characters",
                  },
                })}
                label="Reserved For"
                size="sm"
                isInvalid={errors.reserved_for}
                errorMessage={errors.reserved_for?.message}
              />
              <Button isLoading={submitting} type="submit" color="primary">
                Add
              </Button>
            </form>
          </CardBody>
        </Card>
        <YachtCodesTable
          isLoading={codesLoading}
          data={codesList ? codesList.data : []}
          loadingState={loadingState}
          onRowAction={(item) => {
            setCode(item);
            onOpen();
          }}
          onRowDelete={(code) => {
            setCode(code);
            onDeleteOpen();
          }}
          bottomContent={
            pages > 0 ? (
              <div className="flex w-full justify-center">
                <Pagination
                  showControls
                  showShadow
                  color="primary"
                  page={page}
                  total={stablePages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            ) : null
          }
        />
      </div>

      <Modal isOpen={confirmIsOpen} onOpenChange={confirmOnOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Are you sure you want to add this code?</ModalHeader>
              <ModalFooter className="w-full flex flex-row gap-3 items-center justify-center">
                {submitting ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      onPress={() => {
                        submitData(
                          {
                            code: getValues("code"),
                            reserved_for: getValues("reserved_for"),
                          },
                          onClose
                        );
                      }}
                      fullWidth
                      color="success"
                      isDisabled={submitting}
                    >
                      Yes
                    </Button>
                    <Button
                      fullWidth
                      color="danger"
                      onPress={onClose}
                      isDisabled={submitting}
                    >
                      No
                    </Button>
                  </>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <YachtCodesDeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        code={code}
        onDelete={onDelete}
        loading={deleting}
        error={deleteError}
      />

      <YachtCodesModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        code={code}
      />
    </>
  );
}
