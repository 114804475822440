import { Card } from "@nextui-org/react";
import { MdError } from "react-icons/md";

const ErrorChip = ({ children, className }) => {
    return (
        <Card className={`flex-grow bg-warning-300 py-1 px-3 mb-6 flex flex-row items-center space-x-4 ${className}`}>
            <MdError size={20} />
            <span>
                {children}
            </span>
        </Card>
    );
}

export default ErrorChip;