import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService"; // Import your actual service
import { VerifyUserCard } from "./VerifyUser";
import { snakeToSpaced } from "../../helpers/stringHelpers";

const StakingRecords = ({ qr_hash }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const {
    data: stakingRecords,
    isLoading: stakingLoading,
    isRefetching: stakingRefetching,
  } = useQuery({
    queryKey: ["getStakingRecords", qr_hash],
    queryFn: () =>
      AdminService.getStakingRecords({
        qr_hash: qr_hash,
        skipErrorRedirect: true,
      }),
    enabled: !!qr_hash,
  });

  const totalPages = useMemo(() => {
    return Math.ceil((stakingRecords?.length || 0) / itemsPerPage);
  }, [stakingRecords, itemsPerPage]);

  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return stakingRecords?.slice(startIndex, endIndex);
  }, [stakingRecords, currentPage, itemsPerPage]);

  return (
    <div className="w-full mt-6">
      <VerifyUserCard title={"Staking Records"}>
        {stakingLoading || stakingRefetching ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="overflow-x-scroll">
              <table className="table border-no tbl-btn short-one">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Token Stake Amount</th>
                    <th>APR</th>
                    <th>Maturity Date</th>
                    <th>Token Return</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((record, index) => (
                    <tr
                      className={`border-y trans-td-list dark:!text-white ${index % 2 === 0 ? "bg-gray-900" : ""}`}
                      key={record.hash}
                    >
                      <td>{snakeToSpaced(record.type)}</td>
                      <td>
                        {Intl.NumberFormat("en-US").format(
                          Number(record.token_stake_amount),
                        )}
                      </td>
                      <td>{(record.apr * 100).toFixed(2)}%</td>
                      <td>
                        {new Date(
                          record.maturity_datetime,
                        ).toLocaleDateString()}
                      </td>
                      <td>
                        {Intl.NumberFormat("en-US").format(
                          Number(record.token_return),
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-pagenation mb-3">
              <p className="ms-0">
                Showing{" "}
                <span>
                  {(currentPage - 1) * itemsPerPage + 1}-
                  {Math.min(currentPage * itemsPerPage, stakingRecords?.length)}
                </span>{" "}
                from <span>{stakingRecords?.length}</span> data
              </p>
              <nav>
                <ul className="pagination pagination-gutter pagination-primary no-bg">
                  <li className="page-item page-indicator">
                    <button
                      className="page-link"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i + 1}
                      className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item page-indicator">
                    <button
                      className="page-link"
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </VerifyUserCard>
    </div>
  );
};

export default StakingRecords;
