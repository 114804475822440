import axiosInstance from "./AxiosInstance";

export default class YachtService {
  static async getList({ code, per_page = 15, page = 1, reserved_for }) {
    const listResponse = await axiosInstance.get("/yacht-club/list", {
      params: { code, per_page, page, reserved_for },
    });

    return listResponse.data;
  }

  static async view(code) {
    const viewResponse = await axiosInstance.get(`/yacht-club/view`, {
      params: { code },
    });

    return viewResponse.data;
  }

  static async edit({ code, reserved_for }) {
    const editResponse = await axiosInstance.post(`/yacht-club/edit`, {
      code,
      reserved_for,
    });

    return editResponse.data;
  }

  static async add({ code, reserved_for }) {
    const addResponse = await axiosInstance.post("/yacht-club/add", {
      code,
      reserved_for,
    });

    return addResponse.data;
  }

  static async claim({ code, user_qr_hash }) {
    const claimResponse = await axiosInstance.post("/yacht-club/claim", {
      params: {
        code,
        user_qr_hash,
      },
    });

    return claimResponse.data;
  }

  static async delete({ code }) {
    const deleteResponse = await axiosInstance.delete("/yacht-club/delete", {
      params: { code },
    });

    return deleteResponse.data;
  }
}
