import React, { useState, useContext } from "react";
/// React router dom
import { Link } from "react-router-dom";

import logo from "../../../images/srt-logo-full.svg";
import smlogo from "../../../images/srt-icon.svg";
import "../../../css/tw-style.css";
import { ThemeContext } from "../../../context/ThemeContext";


const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);

  const handleToggle = () => {
    setToggle((prevState) => !prevState);
    openMenuToggle();
  }

  return (
    <div className="nav-header">
      <Link to="/dashboard-dark" className="brand-logo">
        <img src={logo} alt="logo" className="hidden lg:block"></img>
        <img src={smlogo} alt="logo" className="lg:hidden"></img>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          handleToggle();
          //SideBarOverlay();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span><span className="line"></span><span className="line"></span>
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="22" y="11" width="4" height="4" rx="2" fill="#2A353A" />
            <rect x="11" width="4" height="4" rx="2" fill="#2A353A" />
            <rect x="22" width="4" height="4" rx="2" fill="#2A353A" />
            <rect x="11" y="11" width="4" height="4" rx="2" fill="#2A353A" />
            <rect x="11" y="22" width="4" height="4" rx="2" fill="#2A353A" />
            <rect width="4" height="4" rx="2" fill="#2A353A" />
            <rect y="11" width="4" height="4" rx="2" fill="#2A353A" />
            <rect x="22" y="22" width="4" height="4" rx="2" fill="#2A353A" />
            <rect y="22" width="4" height="4" rx="2" fill="#2A353A" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
