import {
  Table,
  TableBody,
  TableHeader,
  Spinner,
  TableRow,
  TableCell,
  TableColumn,
  Chip,
  Snippet,
  Button,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "@nextui-org/react";
import { FaEllipsisV } from "react-icons/fa";

export default function YachtCodesTable({
  data,
  isLoading,
  onRowAction,
  onRowDelete,
  emptyContent = "No Codes for now.",
  loadingContent = <Spinner />,
  loadingState,
  ...props
}) {
  const renderCell = (item, columnKey) => {
    switch (columnKey) {
      case "code":
        return (
          <Snippet symbol="" size="sm">
            <span></span>
            <span>{item[columnKey]}</span>
          </Snippet>
        );
      case "reserved_for":
        return item[columnKey];
      case "status":
        return (
          <Chip
            color={item[columnKey] === "claimed" ? "primary" : "warning"}
            variant="flat"
          >
            {item[columnKey]}
          </Chip>
        );
      case "action":
        return (
          <Dropdown>
            <DropdownTrigger>
              <Button isIconOnly variant="bordered">
                <FaEllipsisV />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Actions">
              <DropdownItem
                onPress={() => {
                  onRowAction(item["code"]);
                }}
                key={"edit"}
              >
                Edit Code
              </DropdownItem>
              <DropdownItem
                onPress={() => {
                  onRowDelete(item["code"]);
                }}
                className="text-danger"
                color="danger"
                key={"delete"}
              >
                Delete Code
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        );
      default:
        return item[columnKey] ?? "N/A";
    }
  };

  return (
    <Table isStriped {...props}>
      <TableHeader>
        <TableColumn key="code">Code</TableColumn>
        <TableColumn key="reserved_for">Reserved For</TableColumn>
        <TableColumn key="status">Status</TableColumn>
        <TableColumn key="action">Action</TableColumn>
      </TableHeader>

      <TableBody
        items={data ?? []}
        isLoading={isLoading}
        loadingContent={loadingContent}
        emptyContent={emptyContent}
        loadingState={loadingState}
      >
        {(item) => (
          <TableRow key={item.code}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
