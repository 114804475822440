import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService"; // Import your actual service
import { VerifyUserCard } from "./VerifyUser";
import { snakeToSpaced } from "../../helpers/stringHelpers";

const StakingOngoingTable = ({ ongoingList, ongoingListLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = useMemo(() => {
    return Math.ceil((ongoingList?.length || 0) / itemsPerPage);
  }, [ongoingList, itemsPerPage]);

  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return ongoingList?.slice(startIndex, endIndex);
  }, [ongoingList, currentPage, itemsPerPage]);

  return (
    <div className="w-full mt-6">
      <VerifyUserCard title={"Ongoing Staking"}>
        {ongoingListLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="overflow-x-scroll">
              <table className="table border-no tbl-btn short-one">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Token Staked</th>
                    <th>APR</th>
                    <th>Maturity Date</th>
                    <th>User ID</th>
                    <th>VIP Status</th>
                    <th>Start Date</th>
                    <th>Duration</th>
                    <th>Adjusted Additional Bonus</th>
                    <th>Extended Days Lapsed</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData?.map((record, index) => (
                    <tr
                      className={`border-y trans-td-list dark:!text-white ${index % 2 === 0 ? "bg-gray-900" : ""}`}
                      key={record.hash}
                    >
                      <td>{snakeToSpaced(record.type)}</td>
                      <td>
                        {Intl.NumberFormat("en-US").format(
                          Number(record.token_received),
                        )}
                      </td>
                      <td>{(record.apr * 100).toFixed(2)}%</td>
                      <td>
                        {new Date(
                          record.maturity_datetime,
                        ).toLocaleDateString()}
                      </td>
                      <td>{record.user_id}</td>
                      <td>{record.is_vip}</td>
                      <td>
                        {new Date(record.start_datetime).toLocaleDateString()}
                      </td>
                      <td>{`${record.duration_amount} ${record.duration_type}`}</td>
                      <td>{record.additional_bonus}</td>
                      <td>{record.extended_days_lapsed}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-pagenation mb-3">
              <p className="ms-0">
                Showing{" "}
                <span>
                  {(currentPage - 1) * itemsPerPage + 1}-
                  {Math.min(currentPage * itemsPerPage, ongoingList?.length)}
                </span>{" "}
                from <span>{ongoingList?.length}</span> data
              </p>
              <nav>
                <ul className="pagination pagination-gutter pagination-primary no-bg">
                  <li className="page-item page-indicator">
                    <button
                      className="page-link"
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <li
                      key={i + 1}
                      className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(i + 1)}
                      >
                        {i + 1}
                      </button>
                    </li>
                  ))}
                  <li className="page-item page-indicator">
                    <button
                      className="page-link"
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </VerifyUserCard>
    </div>
  );
};

export default StakingOngoingTable;
