import ReactApexChart from "react-apexcharts";
import React, { useMemo } from "react";

const ProjectAreaChart = ({ tokenPriceSeries, bnbPriceSeries }) => {
  const seriesLength = useMemo(() => {
    const length1 = tokenPriceSeries?.length ?? 0;
    const length2 = bnbPriceSeries?.length ?? 0;
    return Math.min(length1, length2);
  }, [tokenPriceSeries, bnbPriceSeries]);

  const series = useMemo(() => {
    const data = tokenPriceSeries
      ?.slice(0, seriesLength)
      .map((item) => parseFloat(item.price).toFixed(6));
    return [{ name: "SRT Price", data }];
  }, [tokenPriceSeries, seriesLength]);

  const series2 = useMemo(() => {
    const data = bnbPriceSeries
      ?.slice(0, seriesLength)
      .map((item) => parseFloat(item.price).toFixed(6));
    return [{ name: "BNB Price", data }];
  }, [bnbPriceSeries, seriesLength]);

  const categories = useMemo(() => {
    return tokenPriceSeries
      ?.slice(0, seriesLength)
      .map((item) => new Date(item.time * 1000).toLocaleDateString("en-US"));
  }, [tokenPriceSeries, seriesLength]);

  const options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      categories: categories ?? [],
      title: {
        text: "Days",
      },
      labels: {
        show: false,
      },
    },
    yaxis: [
      {
        seriesName: "SRT Price",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FF1654",
        },
        labels: {
          style: {
            colors: "#FF1654",
          },
        },
        title: {
          text: "SRT Price (USD)",
          style: {
            color: "#FF1654",
          },
        },
      },
      {
        seriesName: "BNB Price",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#247BA0",
        },
        labels: {
          style: {
            colors: "#247BA0",
          },
        },
        title: {
          text: "BNB Price (USD)",
          style: {
            color: "#247BA0",
          },
        },
      },
    ],
  };

  return (
    !!tokenPriceSeries &&
    !!bnbPriceSeries && (
      <ReactApexChart
        options={options}
        series={[...series, ...series2]}
        type="line"
        height={350}
      />
    )
  );
};

export default ProjectAreaChart;
