import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService"; // Import your actual service
import { VerifyUserCard } from "./VerifyUser";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const VerifyUserDownlines = ({ qr_hash }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const pagesToShow = 5;
  const fastForwardInterval = 10;

  const {
    data: userDownlines,
    isLoading: userDownlinesLoading,
    isRefetching: userDownlinesRefetching,
  } = useQuery({
    queryKey: ["getUserDownlines", qr_hash],
    queryFn: () =>
      AdminService.getDownlineUsers({
        qr_hash: qr_hash,
        skipErrorRedirect: true,
      }),
    enabled: !!qr_hash,
  });

  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    const newTotalPages = Math.ceil(userDownlines?.length / itemsPerPage);
    setTotalPages(newTotalPages);

    // If the current page exceeds the new total pages, reset to the first available page
    if (currentPage > newTotalPages || currentPage < 1) {
      setCurrentPage(1);
    } else {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentItems(userDownlines?.slice(indexOfFirstItem, indexOfLastItem));
    }

    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(newTotalPages, startPage + pagesToShow - 1);

    const newPageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      newPageNumbers.push(i);
    }
    setPageNumbers(newPageNumbers);
  }, [userDownlines, currentPage, itemsPerPage, pagesToShow]);

  return (
    <div className="w-full mt-6">
      <VerifyUserCard title={"Indirect Downlines"}>
        {userDownlinesLoading || userDownlinesRefetching ? (
          <Skeleton count={5} height={50} className="my-1" />
        ) : (
          <>
            <div className="overflow-x-scroll">
              <table className="table border-no tbl-btn short-one">
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Birthdate</th>
                    <th>Country</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((user, index) => (
                    <tr
                      className={`border-y trans-td-list dark:!text-white cursor-pointer ${index % 2 === 0 ? "bg-gray-900" : ""}`}
                      key={user.qr_hash}
                      onClick={() => {
                        window.open(
                          `/users-to-verify/${user.qr_hash}`,
                          "_blank",
                        );
                      }}
                    >
                      <td>{user.username}</td>
                      <td>{user.firstname}</td>
                      <td>{user.lastname}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.birthdate
                          ? new Date(user.birthdate * 1000).toLocaleDateString()
                          : "N/A"}
                      </td>
                      <td>{user.country}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="table-pagenation mb-3 w-full overflow-x-scroll">
              <p className="ms-0">
                Showing{" "}
                <span>
                  {(currentPage - 1) * itemsPerPage + 1}-
                  {currentPage * itemsPerPage}
                </span>{" "}
                from <span>{userDownlines?.length}</span> data
              </p>
              <nav>
                <ul className="pagination pagination-gutter pagination-primary no-bg">
                  <li className="page-item page-indicator">
                    <div
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(Math.max(1, currentPage - 1))
                      }
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </div>
                  </li>
                  {currentPage > 1 && (
                    <li className="page-item page-indicator">
                      <div
                        className="page-link"
                        onClick={() =>
                          setCurrentPage(
                            Math.max(1, currentPage - fastForwardInterval),
                          )
                        }
                      >
                        <FaAngleDoubleLeft />
                      </div>
                    </li>
                  )}
                  {pageNumbers.map((number) => (
                    <li
                      key={number}
                      className={`page-item ${number === currentPage ? "active" : ""}`}
                    >
                      <div
                        className="page-link"
                        onClick={() => setCurrentPage(number)}
                      >
                        {number}
                      </div>
                    </li>
                  ))}
                  {currentPage < totalPages && (
                    <li className="page-item page-indicator">
                      <div
                        className="page-link"
                        onClick={() =>
                          setCurrentPage(
                            Math.min(
                              totalPages,
                              currentPage + fastForwardInterval,
                            ),
                          )
                        }
                      >
                        <FaAngleDoubleRight />
                      </div>
                    </li>
                  )}
                  <li className="page-item page-indicator me-0">
                    <div
                      className="page-link"
                      onClick={() =>
                        setCurrentPage(Math.min(totalPages, currentPage + 1))
                      }
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        )}
      </VerifyUserCard>
    </div>
  );
};

export default VerifyUserDownlines;
