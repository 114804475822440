import { FaIdCard } from "react-icons/fa";
import { FaShip } from "react-icons/fa6";
import { IoPeople } from "react-icons/io5";
export const MenuList = [
  {
    title: "Users to Verify",
    iconStyle: <IoPeople scale={1.2} className="!mr-4" color="auto" />,
    to: "users-to-verify",
    type: "admin",
  },
  {
    title: "Control Panel",
    iconStyle: <IoPeople scale={1.2} className="!mr-4" color="auto" />,
    to: "control-panel",
    type: "admin",
  },
  {
    title: "KYC Admin",
    iconStyle: <FaIdCard scale={1.2} className="!mr-4" color="auto" />,
    to: "kyc-admin",
    type: "admin",
  },
  {
    title: "Yacht Codes",
    iconStyle: <FaShip scale={1.2} className="!mr-4" color="auto" />,
    to: "yacht-codes",
    type: "admin",
  },
  {
    title: "Dashboard",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">dashboard</i>,
    to: "",
  },
  {
    title: "Community",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">people</i>,
    to: "community",
  },
  {
    title: "Referral Bonus",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">money</i>,
    to: "referral-bonus",
  },
  {
    title: "Community Bonus",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">money</i>,
    to: "community-bonus",
  },
  {
    title: "Staking",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">house</i>,
    to: "staking",
  },
  // {
  //   title: "Community Staking",
  //   //classsChange: 'mm-collapse',
  //   iconStyle: <i className="material-icons">house</i>,
  //   to: "ticketing2",
  // },

  // {
  //     title: 'File Manager',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">folder</i>,
  //     content: [
  //         {
  //             title: 'File Manager',
  //             to: 'file-manager',
  //         },
  //         {
  //             title: 'User',
  //             to: 'user',
  //         },
  //         {
  //             title: 'Calendar',
  //             to: 'calendar',
  //         },
  //         {
  //             title: 'To Do List',
  //             to: 'to-do-list',
  //         },
  //         {
  //             title: 'Chat',
  //             to: 'chat',
  //         },
  //         {
  //             title: 'Activity',
  //             to: 'activity',
  //         },
  //     ],
  // },
  // {
  //     title: "Academy",
  //     classsChange: "mm-collapse",
  //     update: "New",
  //     iconStyle: <i className="mdi-bank" />,
  //     content: [
  //         {
  //             title: 'Course List',
  //             to: '/course-listing'
  //         },
  //         {
  //             title: 'Course Details',
  //             to: '/course-details'
  //         }
  //     ],
  // },

  {
    title: "Swap History",
    //classsChange: 'mm-collapse',
    iconStyle: <i className="material-icons">star</i>,
    to: "swap-history",
  },

  {
    title: "KYC",
    classsChange: "menu-title",
  },

  {
    title: " ",
    iconStyle: (
      <div className="flex flex-row items-center">
        <FaIdCard scale={1.2} className="!mr-4 !text-customgray" />
        <span>Add</span>
      </div>
    ),
    to: "kyc-add",
  },

  {
    title: " ",
    iconStyle: (
      <div className="flex flex-row items-center">
        <FaIdCard scale={1.2} className="!mr-4 !text-customgray" />{" "}
        <span>Review Status</span>{" "}
      </div>
    ),
    to: "kyc-review-status",
  },
  // {
  //     title: 'AI Trading',
  //     //classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">star</i>,
  //     to: 'crypto',
  // },

  //CMS
  // {
  //     title : "CMS",
  //     classsChange: 'mm-collapse',
  //     update:"New",
  //     iconStyle: <i className="material-icons">settings</i>,
  //     content:[
  //         {
  //             title:'Content',
  //             to:'/content'
  //         },
  //         {
  //             title:'Menu',
  //             to:'/menu'
  //         },
  //         {
  //             title:'Email Template',
  //             to:'/email-template'
  //         },
  //         {
  //             title:'Blog',
  //             to:'/blog'
  //         },
  //     ],
  // },
  //Apps
  // {
  //     title: 'Apps',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-050-info">app_registration </i>,
  //     content: [
  //         {
  //             title: 'Profile',
  //             to: 'app-profile'
  //         },
  //         {
  //             title: 'Edit Profile',
  //             to: 'edit-profile'
  //         },
  //         {
  //             title: 'Post Details',
  //             to: 'post-details'
  //         },
  //         {
  //             title: 'Email',
  //             //to: './',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Compose',
  //                     to: 'email-compose',
  //                 },
  //                 {
  //                     title: 'Index',
  //                     to: 'email-inbox',
  //                 },
  //                 {
  //                     title: 'Read',
  //                     to: 'email-read',
  //                 }
  //             ],
  //         },
  //         {
  //             title:'Calendar',
  //             to: 'app-calender'
  //         },
  //         {
  //             title: 'Shop',
  //             //to: './',
  //             hasMenu : true,
  //             content: [
  //                 {
  //                     title: 'Product Grid',
  //                     to: 'ecom-product-grid',
  //                 },
  //                 {
  //                     title: 'Product List',
  //                     to: 'ecom-product-list',
  //                 },
  //                 {
  //                     title: 'Product Details',
  //                     to: 'ecom-product-detail',
  //                 },
  //                 {
  //                     title: 'Order',
  //                     to: 'ecom-product-order',
  //                 },
  //                 {
  //                     title: 'Checkout',
  //                     to: 'ecom-checkout',
  //                 },
  //                 {
  //                     title: 'Invoice',
  //                     to: 'ecom-invoice',
  //                 },
  //                 {
  //                     title: 'Customers',
  //                     to: 'ecom-customers',
  //                 },
  //             ],
  //         },
  //     ],
  // },
  // //Charts
  // {
  //     title: 'Charts',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">assessment</i>,
  //     content: [
  //
  //         {
  //             title: 'RechartJs',
  //             to: 'chart-rechart',
  //         },
  //         {
  //             title: 'Chartjs',
  //             to: 'chart-chartjs',
  //         },
  //         {
  //             title: 'Sparkline',
  //             to: 'chart-sparkline',
  //         },
  //         {
  //             title: 'Apexchart',
  //             to: 'chart-apexchart',
  //         },
  //     ]
  // },
  // //Boosttrap
  // {
  //     title: 'Bootstrap',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">favorite</i>,
  //     content: [
  //         {
  //             title: 'Accordion',
  //             to: 'ui-accordion',
  //         },
  //         {
  //             title: 'Alert',
  //             to: 'ui-alert',
  //         },
  //         {
  //             title: 'Badge',
  //             to: 'ui-badge',
  //         },
  //         {
  //             title: 'Button',
  //             to: 'ui-button',
  //         },
  //         {
  //             title: 'Modal',
  //             to: 'ui-modal',
  //         },
  //         {
  //             title: 'Button Group',
  //             to: 'ui-button-group',
  //         },
  //         {
  //             title: 'List Group',
  //             to: 'ui-list-group',
  //         },
  //         {
  //             title: 'Cards',
  //             to: 'ui-card',
  //         },
  //         {
  //             title: 'Carousel',
  //             to: 'ui-carousel',
  //         },
  //         {
  //             title: 'Dropdown',
  //             to: 'ui-dropdown',
  //         },
  //         {
  //             title: 'Popover',
  //             to: 'ui-popover',
  //         },
  //         {
  //             title: 'Progressbar',
  //             to: 'ui-progressbar',
  //         },
  //         {
  //             title: 'Tab',
  //             to: 'ui-tab',
  //         },
  //         {
  //             title: 'Typography',
  //             to: 'ui-typography',
  //         },
  //         {
  //             title: 'Pagination',
  //             to: 'ui-pagination',
  //         },
  //         {
  //             title: 'Grid',
  //             to: 'ui-grid',
  //         },
  //     ]
  // },
  // //plugins
  // {
  //     title:'Plugins',
  //     classsChange: 'mm-collapse',
  //     iconStyle : <i className="material-icons">extension </i>,
  //     content : [
  //         {
  //             title:'Select 2',
  //             to: 'uc-select2',
  //         },
  //         // {
  //         //     title:'Noui Slider',
  //         //     to: 'uc-noui-slider',
  //         // },
  //         {
  //             title:'Sweet Alert',
  //             to: 'uc-sweetalert',
  //         },
  //         {
  //             title:'Toastr',
  //             to: 'uc-toastr',
  //         },
  //         {
  //             title:'Jqv Map',
  //             to: 'map-jqvmap',
  //         },
  //         {
  //             title:'Light Gallery',
  //             to: 'uc-lightgallery',
  //         },
  //     ]
  // },
  // //Widget
  // {
  //     title:'Widget',
  //     //classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">widgets</i>,
  //     to: 'widget',
  // },
  // //Forms
  // {
  //     title:'Forms',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">insert_drive_file</i>,
  //     content : [
  //         {
  //             title:'Form Elements',
  //             to: 'form-element',
  //         },
  //         {
  //             title:'Wizard',
  //             to: 'form-wizard',
  //         },
  //         {
  //             title:'CkEditor',
  //             to: 'form-ckeditor',
  //         },
  //         {
  //             title:'Pickers',
  //             to: 'form-pickers',
  //         },
  //         {
  //             title:'Form Validate',
  //             to: 'form-validation',
  //         },
  //
  //     ]
  // },
  // //Table
  // {
  //     title:'Table',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="material-icons">table_chart</i>,
  //     content : [
  //         {
  //             title:'Table Filtering',
  //             to: 'table-filtering',
  //         },
  //         {
  //             title:'Table Sorting',
  //             to: 'table-sorting',
  //         },
  //         {
  //             title:'Bootstrap',
  //             to: 'table-bootstrap-basic',
  //         },
  //
  //
  //     ]
  // },
  // //Pages
  // {
  //     title:'Pages',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="merial-icons">article</i>,
  //     content : [
  //         {
  //             title:'Error',
  //             hasMenu : true,
  //             content : [
  //                 {
  //                     title: 'Error 400',
  //                     to : 'page-error-400',
  //                 },
  //                 {
  //                     title: 'Error 403',
  //                     to : 'page-error-403',
  //                 },
  //                 {
  //                     title: 'Error 404',
  //                     to : 'page-error-404',
  //                 },
  //                 {
  //                     title: 'Error 500',
  //                     to : 'page-error-500',
  //                 },
  //                 {
  //                     title: 'Error 503',
  //                     to : 'page-error-503',
  //                 },
  //             ],
  //         },
  //         {
  //             title:'Lock Screen',
  //             to: 'page-lock-screen',
  //         },
  //
  //     ]
  // },
];
