import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  Input,
  Select,
  SelectItem,
  Spinner,
  Textarea,
} from "@nextui-org/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaIdCard } from "react-icons/fa";
import { toast } from "react-toastify";
import KycService from "../../../services/KycService";

const idTypes = [
  //   {
  //     title: "Postal ID",
  //     value: "postal_id",
  //   },
  {
    title: "National ID",
    value: "national_id",
  },
  {
    title: "Passport",
    value: "passport",
  },
  {
    title: "Driver's License",
    value: "drivers_license",
  },
  //   {
  //     title: "Voter's ID",
  //     value: "voters_id",
  //   },
  //   {
  //     title: "SSS ID",
  //     value: "sss_id",
  //   },
  //   {
  //     title: "GSIS ID",
  //     value: "gsis_id",
  //   },
  //   {
  //     title: "UMID",
  //     value: "umid",
  //   },
  //   {
  //     title: "Philhealth ID",
  //     value: "philhealth_id",
  //   },
  //   {
  //     title: "OFW ID",
  //     value: "ofw_id",
  //   },
  //   {
  //     title: "Senior Citizen ID",
  //     value: "senior_citizen_id",
  //   },
  //   {
  //     title: "PWD ID",
  //     value: "pwd_id",
  //   },
];

export default function KycAdd() {
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      doc_type: "",
      vip_code: "",
      notes: "",
    },
  });

  const onSubmit = (data) => {
    setSubmitting(true);
    toast
      .promise(KycService.add({ ...data, doc_image: imageUrl }), {
        pending: "Submitting KYC...",
        success: "KYC submitted",
      })
      .then(() => {
        setImageUrl(null);
        reset();
      })
      .catch((e) => {
        const errorMessage =
          e.response.data.message ??
          e.response.data.error ??
          "Something went wrong";

        toast.error(errorMessage);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handlePhotoClick = () => {
    if (!uploading) {
      fileInputRef.current.click();
    }
  };

  const handlePhotoUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file) => {
    setUploading(true);
    toast
      .promise(KycService.uploadDocument(file), {
        pending: "Uploading document...",
        success: "Document uploaded",
      })
      .then((response) => {
        setImageUrl(response.image_url);
        toast.success("Document uploaded");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex items-center justify-center flex-col space-y-3"
    >
      <Card fullWidth>
        <CardHeader className="z-0">
          <span className="text-2xl font-bold">Add KYC</span>
        </CardHeader>
        <CardBody className="items-start justify-start flex flex-col sm:flex-row gap-6">
          <div className="flex flex-col gap-6 w-full sm:w-1/2 justify-start">
            <Select
              {...register("doc_type", { required: "This field is required" })}
              name="doc_type"
              items={idTypes}
              label="Document Type"
              placeholder="Select a document type"
              errorMessage={errors.doc_type?.message}
              isInvalid={errors.doc_type}
            >
              {(idType) => (
                <SelectItem value={idType.value} key={idType.value}>
                  {idType.title}
                </SelectItem>
              )}
            </Select>
            <Input
              {...register("vip_code", { required: "This field is required" })}
              label="VIP Code"
              placeholder="Please enter VIP code"
              errorMessage={errors.vip_code?.message}
              isInvalid={errors.vip_code}
            />
            <Textarea
              {...register("notes")}
              label="Notes"
              placeholder="Enter a note..."
            />
          </div>
          <div className="flex flex-col justify-between w-full sm:w-1/2 space-y-6">
            <Card className="min-h-[150px] w-full bg-zinc-800 hover:opacity-80">
              <CardBody
                className="items-center justify-center cursor cursor-pointer"
                onClick={handlePhotoClick}
              >
                <input
                  type="file"
                  className="hidden"
                  onChange={handlePhotoUpload}
                  ref={fileInputRef}
                />
                {imageUrl ? (
                  <div className="flex-shrink-0 rounded-lg h-[150px] flex items-center justify-center bg-default-300/30">
                    <Image src={imageUrl} className="h-[150px]" />
                  </div>
                ) : (
                  <div className="flex flex-col space-y-2 items-center justify-center">
                    <FaIdCard size={30} className="text-zinc-500" />
                    {uploading ? (
                      <Spinner />
                    ) : (
                      <span className="font-bold text-xl text-zinc-500">
                        Upload photo
                      </span>
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
            <Button
              isDisabled={!watch("doc_type") || !watch("vip_code") || !imageUrl}
              color="success"
              type="submit"
              fullWidth
              isLoading={submitting}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
    </form>
  );
}
