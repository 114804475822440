import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import { useQuery } from "@tanstack/react-query";
import BonusService from "../../../services/BonusService";
import Skeleton from "react-loading-skeleton";
import "../../../css/tw-style.css";
import CommunityBonusTable from "../table/CommunityBonusTable";
import { Dropdown } from "react-bootstrap";

const BalanceBarChart = loadable(() =>
  pMinDelay(import("./Banking/BalanceBarChart"), 1000),
);
const ProjectAreaChart = loadable(() =>
  pMinDelay(import("./Banking/ProjectAreaChart"), 1000),
);

const CommunityBonus = () => {
  const date = new Date();
  const options = { month: "long", year: "numeric" };
  const monthYear = date.toLocaleDateString("en-US", options);

  const periods = [7, 30, 90];
  const [currentPeriod, setCurrentPeriod] = useState(periods[1]);

  const {
    data: monthlyBonusTotal,
    isLoading: monthlyBonusLoading,
    refetch: monthlyBonusRefetch,
    isRefetching: monthlyBonusRefetching,
  } = useQuery({
    queryKey: ["monthlyCommunityBonusTotal"],
    queryFn: () => BonusService.getIndirectBonusTotal(""),
  });

  useEffect(() => {
    monthlyBonusRefetch();
  }, [currentPeriod, monthlyBonusRefetch]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="page-titles">
            <div className="d-flex align-items-center">
              <h2 className="heading">Community Referral Bonus</h2>
            </div>
          </div>
        </div>
        <div className="col-12">
          {/* <!-- Row --> */}
          <div className="row">
            {/* <!-- ----column---- --> */}
            <div className="col-xl-12">
              <div className="card your_balance">
                <div className="card-header border-0">
                  <div>
                    <h2 className="heading mb-1">Total Community Earned</h2>
                  </div>
                  {/* <Dropdown className="me-2 drop-select">
                    <Dropdown.Toggle
                      as="div"
                      className="i-false drop-select-btn"
                    >
                      Last {currentPeriod} days{" "}
                      <i className="fa-solid fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {periods.map((period) => (
                        <Dropdown.Item
                          key={period}
                          active={currentPeriod === period}
                          onClick={() => setCurrentPeriod(period)}
                        >
                          Last {period} days
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
                <div className="card-body pt-0 custome-tooltip">
                  <div className="row gx-0">
                    <div className="col-xl-4 col-sm-4">
                      <div className="mothly-income flex flex-col">
                        {/* <span>Last {currentPeriod} days</span> */}
                        {monthlyBonusLoading || monthlyBonusRefetching ? (
                          <Skeleton width={100} height={20} />
                        ) : (
                          <h4>
                            SRT{" "}
                            {Intl.NumberFormat("en-US").format(
                              Number(monthlyBonusTotal).toFixed(2),
                            )}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CommunityBonusTable />
          </div>
        </div>
      </div>
    </>
  );
};
export default CommunityBonus;
