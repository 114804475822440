import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { createContext } from "react";
import UsersService from "../services/UsersService";

export const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};

const ProfileContextProvider = (props) => {
  const { token } = localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails"))
    : { token: null };

  const {
    data: userProfile,
    isLoading: userProfileLoading,
    isRefetching: userProfileIsRefetching,
    refetch: refetchuserProfile,
  } = useQuery({
    queryKey: ["userProfile", "context"],
    queryFn: () => UsersService.getProfile({ skipErrorRedirect: true }),
    enabled: !!token,
  });

  // console.log("user profile:", userProfile);

  return (
    <ProfileContext.Provider
      value={{
        userProfile,
        userProfileLoading,
        userProfileIsRefetching,
        refetchuserProfile,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileContextProvider;
