import { lazy, Suspense, useContext, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Navigate,
} from "react-router-dom";
// action
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "./other/swiper/css/swiper-bundle.min.css";
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ThemeContext } from "./context/ThemeContext";
import { useQueryClient } from "@tanstack/react-query";
import Buy from "./jsx/components/Dashboard/Buy";
import ResetPassword from "./jsx/pages/ResetPassword";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = JSON.parse(localStorage.getItem("userDetails"))?.token
    ? true
    : false;

  // const { data: userProfile, isLoading: profileLoading } = useQuery({
  //     queryKey: ["profile"],
  //     queryFn: () => UsersService.getProfile({ skipErrorRedirect: true }),
  //     enabled: props.isAuthenticated
  // });

  // useEffect(() => {
  //     if (userProfile) {
  //         console.log("user profile:", userProfile);
  //     }
  // }, [userProfile]);

  const { openMenuToggle } = useContext(ThemeContext);
  const location = useLocation();

  useEffect(() => {
    // reset menu bar
    openMenuToggle();
  }, []);

  useEffect(() => {
    const deleteToken =
      location.pathname === "/login" || location.pathname === "/signup" || location.pathname === "/forgot-password";
    // const skipRedirect = location.pathname === '/signup';
    // checkAutoLogin(dispatch, navigate, skipRedirect);
    if (deleteToken) {
      localStorage.removeItem("userDetails");
    }
  }, [dispatch, navigate, location]);

  // useEffect(() => {
  //     if (location.pathname === '/login') {
  //         dispatch(Logout2());
  //     }
  // }, [dispatch, location, navigate]);

  // Inside your Login component

  // ... rest of your component

  let routeblog = (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/buy" element={<Buy />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
    </Routes>
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoggedIn) {
      queryClient.clear();
    }
  }, [isLoggedIn, queryClient]);

  return (
    <>
      {isLoggedIn ? (
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      ) : (
        <div className="vh-100">
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            {routeblog}
          </Suspense>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
