import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  Skeleton,
  ModalFooter,
  Button,
  Input,
  Chip,
  Snippet,
} from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import YachtService from "../../../services/YachtService";
import { FaArrowLeft, FaLock, FaLockOpen } from "react-icons/fa6";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function YachtCodesModal({
  isOpen,
  onOpen,
  onOpenChange,
  code,
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: yachtData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["yacht", "codes", code],
    queryFn: () => YachtService.view(code),
    enabled: !!code,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      reserved_for: yachtData?.reserved_for,
    },
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    toast
      .promise(
        YachtService.edit({
          code: yachtData?.code,
          reserved_for: data.reserved_for,
        }),
        {
          pending: "Saving...",
          success: "Yacht code updated successfully",
        }
      )
      .then(() => {
        reset();
        refetch();
        setValue("reserved_for", yachtData?.reserved_for ?? "");
        setIsEditMode(false);
      })
      .catch((e) => {
        const errorMessage =
          e.response.data.description ??
          e.response.data.message ??
          e.response.data.error ??
          "Something went wrong";
        toast.error(errorMessage);
        setError(
          "reserved_for",
          { message: errorMessage },
          { shouldFocus: true }
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    reset();
    setValue("reserved_for", yachtData?.reserved_for ?? "");
    setIsEditMode(false);
  }, [isOpen, setValue, yachtData, reset]);

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <form onSubmit={handleSubmit(onSubmit)}>
            {!isLoading && !yachtData ? (
              <>
                <ModalHeader>Something went wrong</ModalHeader>
                <ModalBody className="items-center justify-center">
                  Please try again
                </ModalBody>
                <ModalFooter>
                  <Button
                    onPress={() => {
                      refetch();
                    }}
                    fullWidth
                  >
                    Retry
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <ModalHeader>
                  {isLoading ? (
                    <Skeleton className="rounded-lg">
                      <div className="w-[100px] h-6"></div>
                    </Skeleton>
                  ) : (
                    <div className="flex flex-row items-center space-x-4">
                      <Button
                        onPress={() => setIsEditMode((prevState) => !prevState)}
                        isIconOnly
                        color={`${isEditMode ? "success" : "warning"}`}
                      >
                        {isEditMode ? <FaLockOpen /> : <FaLock />}
                      </Button>
                      <span className="font-bold text-white text-xl flex flex-row items-center space-x-4">
                        <FaArrowLeft />
                        <span>Toggle Edit Mode</span>
                      </span>
                    </div>
                  )}
                </ModalHeader>
                <ModalBody>
                  {isLoading ? (
                    <>
                      <Skeleton className="rounded-lg">
                        <div className="w-full h-6"></div>
                      </Skeleton>
                      <Skeleton className="rounded-lg">
                        <div className="w-full h-6"></div>
                      </Skeleton>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col gap-3">
                        <div className="flex flex-row gap-3 px-3 py-3 rounded-lg bg-zinc-800 font-bold">
                          <span>Code:</span>
                          <span className="text-white">{yachtData?.code}</span>
                        </div>
                        <Input
                          {...register("reserved_for", {
                            required: "This field must not be empty",
                          })}
                          label="Reserved For"
                          placeholder="Please enter name"
                          value={
                            watch("reserved_for") ?? yachtData.reserved_for
                          }
                          onValueChange={(value) => {
                            setValue("reserved_for", value);
                          }}
                          isReadOnly={!isEditMode}
                          isDisabled={!isEditMode}
                          isInvalid={errors.reserved_for}
                          errorMessage={errors.reserved_for?.message}
                        />
                      </div>
                      <div className="flex flex-col gap-3 px-3 py-6 rounded-lg bg-zinc-800">
                        {/* <div className="flex flex-row gap-2">
                          <span>Added By:&nbsp;</span>
                          <span className="text-white">
                            {yachtData.added_by}
                          </span>
                        </div> */}
                        {/* <div className="flex flex-row gap-2">
                          <span>Updated By:&nbsp;</span>
                          <span className="text-white">
                            {yachtData.updated_by}
                          </span>
                        </div> */}
                        <div className="flex flex-row gap-2">
                          <span>Time Added:&nbsp;</span>
                          <span className="text-white">
                            {new Date(
                              yachtData.time_added * 1000
                            ).toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            })}
                          </span>
                        </div>
                        <div className="flex flex-row gap-2">
                          <span>Time Updated:&nbsp;</span>
                          <span className="text-white">
                            {yachtData.time_updated <= 0
                              ? "N/A"
                              : new Date(
                                  yachtData.time_updated * 1000
                                ).toLocaleString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                          </span>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row gap-2 items-center">
                            <span>Status:&nbsp;</span>
                            <span className="text-white">
                              <Chip
                                color={
                                  yachtData.status === "active"
                                    ? "success"
                                    : "primary"
                                }
                                variant="flat"
                              >
                                {yachtData.status}
                              </Chip>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </ModalBody>
                <ModalFooter className="flex flex-col">
                  <Button
                    isLoading={isSubmitting}
                    fullWidth
                    type="submit"
                    isDisabled={!isEditMode}
                    color={isEditMode ? "success" : "warning"}
                  >
                    {isEditMode ? "Edit" : "Please enable edit mode"}
                  </Button>
                </ModalFooter>
              </>
            )}
          </form>
        )}
      </ModalContent>
    </Modal>
  );
}
