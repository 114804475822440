import { Chip } from "@nextui-org/react";

export default function StatusChip({ status }) {
  return (
    <Chip
      color={
        status === "pending"
          ? "warning"
          : status === "approved"
            ? "success"
            : "danger"
      }
      size="sm"
      variant="flat"
    >
      {status}
    </Chip>
  );
}
