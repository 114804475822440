import { useQuery } from "@tanstack/react-query";
import KycService from "../../../services/KycService";
import { useState, useMemo, useEffect } from "react";
import KycTable from "../Tables/KycTable";
import { Spinner, useDisclosure, Pagination } from "@nextui-org/react";
import KycAdminModal from "./KycAdminModal";

export default function KycAdmin() {
  const [page, setPage] = useState(1);
  const { onOpen, isOpen, onOpenChange } = useDisclosure();

  const {
    data: kycList,
    isLoading: kycLoading,
    refetch,
  } = useQuery({
    queryKey: ["kyc", "list", page],
    queryFn: () => KycService.getList({ status: "pending", page }),
  });

  const [selectedQrHash, setSelectedQrHash] = useState("");

  const [stablePages, setStablePages] = useState(null);

  const pages = useMemo(() => {
    return kycList?.last_page;
  }, [kycList]);

  useEffect(() => {
    if (pages) {
      setStablePages(pages);
    }
  }, [pages]);

  useEffect(() => {
    if (!isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  return (
    <>
      <KycTable
        data={kycList ? kycList.data : []}
        loadingContent={<Spinner />}
        isLoading={kycLoading}
        onRowAction={(qr_hash) => {
          setSelectedQrHash(qr_hash);
          onOpen();
        }}
        bottomContent={
          pages > 0 ? (
            <div className="flex w-full justify-center">
              <Pagination
                showControls
                showShadow
                color="primary"
                page={page}
                total={stablePages}
                onChange={(page) => setPage(page)}
              />
            </div>
          ) : null
        }
      />

      <KycAdminModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        qr_hash={selectedQrHash}
      />
    </>
  );
}
