import axiosInstance from "./AxiosInstance";

export default class KycService {
  /**
   * Retrieves a paginated list of KYC records filtered by status
   * @param {Object} params - The parameters for the request
   * @param {string} params.status - Status to filter KYC records (pending, approved, denied)
   * @param {number} params.per_page - Number of records per page. Default 15
   * @returns {Promise<Array>}
   */
  static async getList({ status, per_page = 15, page }) {
    const listResponse = await axiosInstance.get("/kyc/list", {
      params: { status, per_page, page },
    });

    return listResponse.data;
  }

  static async view(kyc_hash) {
    const viewResponse = await axiosInstance.get(`/kyc/view/${kyc_hash}`);

    return viewResponse.data;
  }

  static async approve(kyc_hash) {
    const approveResponse = await axiosInstance.post(
      `/kyc/approve/${kyc_hash}`
    );

    return approveResponse.data;
  }

  static async deny(kyc_hash) {
    const denyResponse = await axiosInstance.post(`/kyc/deny/${kyc_hash}`);

    return denyResponse.data;
  }

  static async add(data) {
    const addResponse = await axiosInstance.post("/kyc/add", data);

    return addResponse.data;
  }

  static async uploadDocument(file) {
    const formData = new FormData();
    formData.append("image", file);

    const uploadResponse = await axiosInstance.post(
      "/kyc/upload-image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return uploadResponse.data;
  }

  static async getMyPending() {
    const myPendingResponse = await axiosInstance.get("/kyc/my-pending");

    return myPendingResponse.data;
  }

  static async getMyApproved() {
    const myApprovedResponse = await axiosInstance.get("/kyc/my-approved");

    return myApprovedResponse.data;
  }
}
