import { Dropdown } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useEffect, useState } from "react";
import BonusService from "../../../services/BonusService";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";

export default function CommunityBonusTable() {
    const [selectBtn, setSelectBtn] = useState("This Month");
    const [searchParams, setSearchParams] = useSearchParams();

    const maxTableRows = 10;
    const currentPage = searchParams.get("page") || 1;

    const { data: communityList, isLoading: communityListLoading, isRefetching: communityListRefetching, refetch: refetchCommunityList } = useQuery({
        queryKey: ["indirectbonuslist"],
        queryFn: () => BonusService.getIndirectCommunityBonusList({ limit: maxTableRows, page: currentPage }),
    });

    useEffect(() => {
        refetchCommunityList();
    }, [currentPage, refetchCommunityList]);

    return (
        <div className="col-12">
            <div className="card lastest_trans">
                <div className="card-header border-0 flex-wrap pb-0">
                    <div>
                        <h2 className="heading">Community Bonus List</h2>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                        <Dropdown className="me-2 drop-select">
                            <Dropdown.Toggle
                                as="div"
                                className="i-false drop-select-btn"
                            >
                                {selectBtn} <i className="fa-solid fa-angle-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => setSelectBtn("This Month")}
                                >
                                    This Month
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                {communityListLoading || communityListRefetching ? (
                    <Skeleton count={5} height={50} className='my-1' />
                ) : (
                    <div className="overflow-x-scroll">
                        <table className="table trans-table border-no tbl-btn short-one">
                            <thead>
                                <tr>
                                    <th>Source</th>
                                    <th>Level</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {communityList?.data.map((data, index) => (
                                    <tr className={`trans-td-list dark:!text-white ${index % 2 === 0 ? "bg-gray-900" : ""}`} key={index}>
                                        <td>
                                            <div className="trans-list">
                                                <div className="user-info">
                                                    <h4>{data.source_username}</h4>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className="doller">Level {data.gen_level}</span>
                                        </td>
                                        <td>
                                            <span className="doller">SRT {Number(data.bonus_received).toFixed(2)}</span>
                                        </td>
                                        <td>
                                            <span className="date">{new Date(data.time_added * 1000).toLocaleString('en-US', { month: "long", year: "numeric", day: "2-digit" })}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <div className="table-pagenation overflow-x-scroll">
                    {communityListLoading ? (
                        <Skeleton height={25} width={150} />
                    ) : (
                        <p>
                            Showing <span>{communityList?.from}-{communityList?.to}</span>from <span>{communityList?.total}</span>data
                        </p>
                    )}

                    <nav>
                        <ul className="pagination pagination-gutter pagination-primary no-bg">
                            <li className="page-item page-indicator">
                                <div className="page-link" onClick={() => setSearchParams({ page: Number(currentPage) - 1 })}>
                                    <i className="fa-solid fa-angle-left"></i>
                                </div>
                            </li>
                            {communityList?.links.map((item, index) => (
                                index === 0 || index === communityList.links.length - 1 ? null : (
                                    <li className={`page-item ${item.active ? "active" : ""}`} onClick={() => item.label !== "..." ? setSearchParams({ page: item.label }) : null} >
                                        <span className="page-link">
                                            {item.label}
                                        </span>
                                    </li>
                                )
                            ))}
                            <li className="page-item page-indicator">
                                <div className="page-link" onClick={() => setSearchParams({ page: Number(currentPage) + 1 })}>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}