import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Snippet,
  Spinner,
} from "@nextui-org/react";

export default function YachtCodesDeleteModal({
  isOpen,
  loading,
  onOpenChange,
  onDelete,
  code,
  error,
}) {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) =>
          loading ? (
            <>
              <ModalHeader></ModalHeader>
              <ModalBody>
                <Spinner />
              </ModalBody>
              <ModalFooter></ModalFooter>
            </>
          ) : error ? (
            <>
              <ModalHeader>Error</ModalHeader>
              <ModalBody>
                <span>{error}</span>
              </ModalBody>
              <ModalFooter>
                <Button onPress={onClose}>Close</Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalHeader>
                Are you sure you want to delete this code?
              </ModalHeader>
              <ModalBody>
                <Snippet className="text-base" symbol="Code: ">
                  <span className="font-bold text-lg">
                    <br />
                    {code}
                  </span>
                </Snippet>
              </ModalBody>
              <ModalFooter className="w-full flex flex-row">
                <Button
                  className="flex-1"
                  color="danger"
                  onPress={() => onDelete(code, onClose)}
                >
                  Yes
                </Button>
                <Button className="flex-1" onPress={onClose}>
                  No
                </Button>
              </ModalFooter>
            </>
          )
        }
      </ModalContent>
    </Modal>
  );
}
