import axiosInstance from './AxiosInstance';

export default class CommunityService {
    static async getDirectCount() {
        const axiosResponse = await axiosInstance.get(`/community/direct-count`);

        return axiosResponse.data.direct_community_count;
    }

    static async getIndirectCount() {
        const axiosResponse = await axiosInstance.get(`/community/indirect-count`);

        return axiosResponse.data.indirect_community_count;
    }

    static async getList() {
        const listResponse = await axiosInstance.get('/community/list');

        return listResponse.data;
    }
}