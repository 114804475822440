import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
  ModalBody,
} from "@nextui-org/react";
import { FaCheck } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

export default function KycAdminActionModal({
  isOpen,
  onOpenChange,
  action,
  onSubmit,
  loading,
  success,
  setSuccess,
  refetch,
  failed,
  setFailed,
}) {
  const actionTextComponent = (str) => {
    return (
      <span
        className={`font-bold ${str === "approve" ? "text-success-500" : "text-red-500"} text-2xl`}
      >
        {str.toUpperCase()}
      </span>
    );
  };

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            {loading ? (
              <>
                <ModalBody className="items-center justify-center">
                  <Spinner className="m-16" />
                </ModalBody>
                <ModalFooter></ModalFooter>
              </>
            ) : success ? (
              <>
                <ModalBody className="items-center justify-center">
                  <div className={`rounded-full p-12 bg-green-500 m-6`}>
                    <FaCheck size={25} color="white" />
                  </div>
                  <span className="text-xl font-bold text-center">
                    Successfully {action === "approve" ? "approved" : "denied"}
                  </span>
                </ModalBody>
                <ModalFooter className="w-full">
                  <Button
                    onPress={() => {
                      setSuccess(false);
                      refetch();
                      onClose();
                    }}
                    fullWidth
                    color="success"
                  >
                    Continue
                  </Button>
                </ModalFooter>
              </>
            ) : failed ? (
              <>
                <ModalBody className="items-center justify-center">
                  <div className={`rounded-full p-12 bg-red-500 m-6`}>
                    <FaX size={25} color="white" />
                  </div>
                  <span className="text-xl font-bold text-center">
                    Something went wrong. Please try again.
                  </span>
                </ModalBody>
                <ModalFooter className="w-full">
                  <Button
                    onPress={() => {
                      setFailed(false);
                      refetch();
                      onClose();
                    }}
                    fullWidth
                    color="success"
                  >
                    Continue
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <ModalHeader className="items-center justify-center text-center flex-col">
                  <span>Are you sure you want to</span>
                  {actionTextComponent(action)}
                  <span>this KYC?</span>
                </ModalHeader>
                <ModalFooter>
                  <div className="w-full flex flex-row gap-3">
                    <Button
                      color="success"
                      onPress={() => onSubmit(action)}
                      fullWidth
                    >
                      Yes
                    </Button>
                    <Button color="danger" onPress={onClose} fullWidth>
                      No
                    </Button>
                  </div>
                </ModalFooter>
              </>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
