import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Skeleton,
  Button,
  Image,
  useDisclosure,
} from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import KycService from "../../../services/KycService";
import StatusChip from "../common/StatusChip";
import { useState } from "react";
import KycAdminActionModal from "./KycAdminActionModal";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { imageSrcBuilder } from "../../helpers/stringHelpers";

const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_PREFIX;

export default function KycAdminModal({ isOpen, onOpenChange, qr_hash }) {
  const {
    isOpen: confirmIsOpen,
    onOpen: confirmOnOpen,
    onOpenChange: confirmOnOpenChange,
  } = useDisclosure();
  const {
    data: kycDetails,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["kyc", "view", qr_hash],
    queryFn: () => KycService.view(qr_hash),
    enabled: !!qr_hash,
  });

  const [action, setAction] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const confirmSubmit = (action) => {
    setConfirmLoading(true);

    // const samplePromise = new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve();
    //   }, 2000);
    // });

    if (action === "approve") {
      // toast
      //   .promise(samplePromise, {
      toast
        .promise(KycService.approve(qr_hash), {
          pending: "Approving KYC...",
          success: "KYC Approved!",
          error: "Failed to approve KYC",
        })
        .then(() => {
          setSuccess(true);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    } else if (action === "deny") {
      // toast
      //   .promise(samplePromise, {
      toast
        .promise(KycService.deny(qr_hash), {
          pending: "Denying KYC...",
          success: "KYC Denied!",
          error: "Failed to deny KYC",
        })
        .then(() => {
          setSuccess(true);
        })
        .catch(() => {
          setFailed(true);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    }
  };

  useEffect(() => {
    if (success && !confirmIsOpen) {
      refetch();
      setSuccess(false);
    }

    if (failed && !confirmIsOpen) {
      refetch();
      setSuccess(false);
    }
  }, [confirmIsOpen, refetch, setSuccess, success, failed]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="blur"
        size="2xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex-1 font-bold">
                {isLoading ? (
                  <Skeleton className="rounded-lg">
                    <div className="w-32 h-6 rounded-lg bg-default-300"></div>
                  </Skeleton>
                ) : (
                  <span className="flex flex-row items-center space-x-2">
                    <span>
                      Qr Hash:{" "}
                      <span className="text-white">{kycDetails.hash}</span>
                    </span>
                    <StatusChip status={kycDetails.status} />
                  </span>
                )}
              </ModalHeader>
              <ModalBody>
                {isLoading ? (
                  <ModalBodySkeleton />
                ) : (
                  <>
                    <div className="flex-shrink-0 rounded-lg h-64 flex items-center justify-center bg-default-300/30">
                      <Image
                        src={imageSrcBuilder(kycDetails.doc_image)}
                        className="h-64"
                      />
                    </div>

                    <div className="flex-shrink-0 flex flex-row gap-1">
                      <div className="flex-1 h-full space-y-3 flex flex-col [&>*:nth-child(odd)]:bg-default-100/30 [&>*:nth-child(even)]:bg-default-100/30">
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          User ID:{" "}
                          <span className="text-white">
                            {kycDetails.user_id}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Hash:{" "}
                          <span className="text-white">{kycDetails.hash}</span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Document Type:{" "}
                          <span className="text-white">
                            {String(kycDetails.doc_type)
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.slice(1) +
                                  " ",
                              )}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Approved By:{" "}
                          <span className="text-white">
                            {kycDetails.approved_by ?? "N/A"}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Time Approved:{" "}
                          <span className="text-white">
                            {kycDetails.time_approved
                              ? new Date(
                                  kycDetails.time_approved * 1000,
                                ).toLocaleString("en-US", {
                                  month: "long",
                                  day: "numeric",
                                  year: "numeric",
                                })
                              : "N/A"}
                          </span>
                        </span>
                      </div>

                      <div className="flex-1 h-full space-y-3 flex flex-col [&>*:nth-child(odd)]:bg-default-100/30 [&>*:nth-child(even)]:bg-default-100/30">
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Time Added:{" "}
                          <span className="text-white">
                            {new Date(
                              kycDetails.time_added * 1000,
                            ).toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                            })}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          VIP Code:{" "}
                          <span className="text-white">
                            {kycDetails.vip_code ?? "N/A"}{" "}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2 items-center">
                          Status:{" "}
                          <span className="text-white">
                            <StatusChip status={kycDetails.status} />
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Denied By:{" "}
                          <span className="text-white">
                            {kycDetails.denied_by ?? "N/A"}
                          </span>
                        </span>
                        <span className="flex-1 flex flex-row justify-between rounded-full px-4 py-2">
                          Time Denied:{" "}
                          <span className="text-white">
                            {kycDetails.time_denied ?? "N/A"}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-start bg-default-100/30 rounded-xl px-4 py-2 border-zinc-300 border">
                      <span>Notes:</span>
                      <span className="text-white">
                        {kycDetails.notes ?? "N/A"}
                      </span>
                    </div>
                  </>
                )}

                {!!kycDetails && kycDetails.status === "pending" && (
                  <div className="flex-shrink-0 flex flex-row gap-3">
                    <Button
                      onPress={() => {
                        setAction("approve");
                        confirmOnOpen();
                      }}
                      isDisabled={isLoading}
                      color="success"
                      fullWidth
                    >
                      Approve
                    </Button>
                    <Button
                      onPress={() => {
                        setAction("deny");
                        confirmOnOpen();
                      }}
                      isDisabled={isLoading}
                      color="danger"
                      fullWidth
                    >
                      Deny
                    </Button>
                  </div>
                )}
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <KycAdminActionModal
        isOpen={confirmIsOpen}
        onOpenChange={confirmOnOpenChange}
        onOpen={confirmOnOpen}
        action={action}
        onSubmit={confirmSubmit}
        loading={confirmLoading}
        success={success}
        setSuccess={setSuccess}
        refetch={refetch}
        failed={failed}
        setFailed={setFailed}
      />
    </>
  );
}

const ModalBodySkeleton = () => {
  return (
    <>
      <Skeleton className="rounded-lg">
        <div className="flex flex-row flex-shrink-0 bg-default-300 h-64"></div>
      </Skeleton>
      <div className="flex-shrink-0 h-32 flex flex-row gap-2">
        <div className="flex-1 h-full space-y-3">
          <Skeleton className="rounded-lg">
            <div className="w-32 h-6 bg-default-300"></div>
          </Skeleton>
          <Skeleton className="rounded-lg">
            <div className="w-64 h-6 bg-default-300"></div>
          </Skeleton>
          <Skeleton className="rounded-lg">
            <div className="w-44 h-6 bg-default-300"></div>
          </Skeleton>
        </div>
        <div className="flex-1 h-full space-y-3">
          <Skeleton className="rounded-lg">
            <div className="w-32 h-6 bg-default-300"></div>
          </Skeleton>

          <Skeleton className="rounded-lg">
            <div className="w-64 h-6 bg-default-300"></div>
          </Skeleton>
          <Skeleton className="rounded-lg">
            <div className="w-44 h-6 bg-default-300"></div>
          </Skeleton>
        </div>
      </div>
    </>
  );
};
