import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AdminService from "../../../services/AdminService";
import Skeleton from "react-loading-skeleton";
import { snakeToSpaced } from "../../helpers/stringHelpers";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaSearch,
} from "react-icons/fa";
import { Input } from "@nextui-org/react";
import { useForm } from "react-hook-form";

const UsersToVerify = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const [selectBtn, setSelectBtn] = useState("");

  const maxTableRows = 10;
  const currentPage = Math.max(1, searchParams.get("page")) || 1;
  const fastForwardInterval = 5;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const search = watch("search");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300); // 300ms debounce period

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const { data: searchData, isLoading: searchLoading } = useQuery({
    queryKey: ["search", debouncedSearch],
    queryFn: () => AdminService.searchUsers({ query: search }),
    enabled: !!search, // only run the query if search is not empty
    staleTime: 5000, // Adjust based on how often you want the data to be considered fresh
    refetchOnWindowFocus: false, // Avoid refetches on window focus
    retry: false, // Avoid retries
  });

  const {
    data: usersToVerify,
    isLoading: usersToVerifyLoading,
    isRefetching: usersToVerifyRefetching,
    refetch: refetchUsersToVerify,
  } = useQuery({
    queryKey: ["usersToVerify"],
    queryFn: () =>
      AdminService.getUsersToVerify({ page: currentPage, limit: maxTableRows }),
  });

  const totalPages = Math.ceil(usersToVerify?.total / maxTableRows);
  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage > totalPages - 4) {
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  useEffect(() => {
    refetchUsersToVerify();
  }, [currentPage, refetchUsersToVerify]);

  const usersToShow = useMemo(() => {
    if (searchData && search) {
      return searchData;
    }
    return usersToVerify?.data;
  }, [searchData, usersToVerify, search]);

  return (
    <div className="col-12">
      <div className="card lastest_trans">
        <div className="card-header border-0 flex-wrap pb-0">
          <div>
            <h2 className="heading">Users to Verify</h2>
          </div>
          <form className="d-flex align-items-center mb-3">
            <Input
              {...register("search")}
              label="Search"
              isClearable
              radius="lg"
              classNames={{
                label: "text-black/50 dark:text-white/90",
                input: [
                  "bg-transparent",
                  "text-black/90 dark:text-white/90",
                  "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                ],
                innerWrapper: "bg-transparent",
                inputWrapper: [
                  "shadow-xl",
                  "bg-default-200/50",
                  "dark:bg-default/60",
                  "backdrop-blur-xl",
                  "backdrop-saturate-200",
                  "hover:bg-default-200/70",
                  "dark:hover:bg-default/70",
                  "group-data-[focus=true]:bg-default-200/50",
                  "dark:group-data-[focus=true]:bg-default/60",
                  "!cursor-text",
                ],
              }}
              placeholder="Type to search..."
              startContent={
                <FaSearch className="text-black/50 mb-0.5 dark:text-white/90 text-slate-400 pointer-events-none flex-shrink-0" />
              }
            />{" "}
          </form>
        </div>
        {usersToVerifyLoading || usersToVerifyRefetching || searchLoading ? (
          <Skeleton count={5} height={50} className="my-1" />
        ) : (
          <div className="overflow-x-scroll">
            <table className="table border-no tbl-btn short-one">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Usergroup</th>
                  <th>Time Created</th>
                </tr>
              </thead>
              <tbody>
                {usersToShow?.map((data, index) => (
                  <tr
                    className={`hover:bg-gray-800 cursor-pointer trans-td-list dark:!text-white ${index % 2 === 0 ? "bg-gray-900" : ""}`}
                    key={index}
                    onClick={() => {
                      navigate(`/users-to-verify/${data.qr_hash}`);
                    }}
                  >
                    <td>
                      <div className="trans-list">
                        <div className="user-info">
                          <h4>{data.username}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="trans-list">
                        <div className="user-info">
                          <h4>{data.firstname}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="trans-list">
                        <div className="user-info">
                          <h4>{data.lastname}</h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="doller !text-white">
                        {snakeToSpaced(data.usergroup)}
                      </span>
                    </td>
                    <td>
                      <span className="date text-white">
                        {new Date(data.time_created * 1000).toLocaleString(
                          "en-US",
                          { month: "long", year: "numeric", day: "2-digit" },
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="table-pagenation overflow-x-scroll">
          {usersToVerifyLoading ? (
            <Skeleton height={25} width={150} />
          ) : (
            <p>
              Showing{" "}
              <span>
                {(currentPage - 1) * maxTableRows + 1} -{" "}
                {(currentPage - 1) * maxTableRows + maxTableRows}
              </span>
              from <span>{usersToVerify?.total}</span>users
            </p>
          )}

          {usersToVerifyLoading ? (
            <Skeleton height={25} width={150} />
          ) : (
            <nav>
              <ul className="pagination pagination-gutter pagination-primary no-bg">
                <li className="page-item page-indicator">
                  <div
                    className="page-link"
                    onClick={() =>
                      setSearchParams({ page: Number(currentPage) - 1 })
                    }
                  >
                    <i className="fa-solid fa-angle-left"></i>
                  </div>
                </li>
                {currentPage > 1 && (
                  <li className="page-item page-indicator">
                    <div
                      className="page-link"
                      onClick={() =>
                        setSearchParams({
                          page: Math.max(
                            1,
                            Number(currentPage) - fastForwardInterval,
                          ),
                        })
                      }
                    >
                      <FaAngleDoubleLeft />
                    </div>
                  </li>
                )}
                {getPageNumbers().map((number) => (
                  <li
                    key={number}
                    className={`page-item ${number === currentPage ? "active" : ""}`}
                  >
                    <div
                      className="page-link"
                      onClick={() => setSearchParams({ page: number })}
                    >
                      {number}
                    </div>
                  </li>
                ))}
                {currentPage < totalPages && (
                  <li className="page-item page-indicator">
                    <div
                      className="page-link"
                      onClick={() =>
                        setSearchParams({
                          page: Math.min(
                            totalPages,
                            Number(currentPage) + fastForwardInterval,
                          ),
                        })
                      }
                    >
                      <FaAngleDoubleRight />
                    </div>
                  </li>
                )}
                <li className="page-item page-indicator">
                  <div
                    className="page-link"
                    onClick={() =>
                      setSearchParams({ page: Number(currentPage) + 1 })
                    }
                  >
                    <i className="fa-solid fa-angle-right"></i>
                  </div>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default UsersToVerify;
