import axiosInstance from './AxiosInstance';

export class Web3Service {
	static async getBnbBalance({ address }) {
		const balanceResponse = await axiosInstance.get('/web3/get-balance', {
			params: {
				address,
			},
		});

		return balanceResponse.data;
	}

	static async getTokenBalance({ address }) {
		const balanceResponse = await axiosInstance.get(
			'/web3/get-token-balance',
			{
				params: {
					address,
				},
			},
		);

		return balanceResponse.data;
	}
}
