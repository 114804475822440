import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorChip from "../../../Errors/ErrorChip";
import { Button, DatePicker, Spinner } from "@nextui-org/react";
import { today, getLocalTimeZone, parseDate } from "@internationalized/date";
import { useProfile } from "../../../../../context/ProfileContext";
import { toast } from "react-toastify";
import UsersService from "../../../../../services/UsersService";
import { MdError } from "react-icons/md";

const ChangeBirthdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [birthdateError, setBirthdateError] = useState("");
  const [birthdate, setBirthdate] = useState("");

  const {
    userProfile,
    userProfileLoading,
    refetchuserProfile,
    userProfileIsRefetching,
  } = useProfile();

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (birthdate) {
      setBirthdateError("");
    }
  }, [birthdate]);

  const handleChangeBirthdateSubmit = (data) => {
    if (!birthdate) {
      setBirthdateError("Birthdate is required");
      return;
    }

    setLoading(true);

    toast
      .promise(
        () =>
          UsersService.updateBirthdate({
            birthdate: birthdate.toString(),
          }),
        {
          pending: "Updating Birthdate...",
          success: "Birthdate Updated",
          error: "Failed to update Birthdate",
        },
      )
      .then(() => {
        refetchuserProfile();
      })
      .catch((e) => {
        setError(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return userProfileLoading || userProfileIsRefetching ? (
    <Spinner />
  ) : !!userProfile?.birthdate ? (
    <div className="rounded-lg w-full px-6 py-3 border-2 border-danger bg-danger/15">
      <div className="text-danger flex flex-row items-center space-x-3">
        <MdError size={25} />
        You can only set your birthday once
      </div>
    </div>
  ) : (
    <form
      onSubmit={handleSubmit(handleChangeBirthdateSubmit)}
      className="space-y-9"
    >
      {!!error && <ErrorChip>{error}</ErrorChip>}
      <DatePicker
        isRequired
        label="Birthdate"
        showMonthAndYearPickers
        fullWidth
        onChange={setBirthdate}
        isInvalid={!!birthdateError}
        errorMessage={birthdateError}
        defaultValue={parseDate(userProfile?.birthdate)}
        maxValue={today(getLocalTimeZone())}
      />

      <div className="w-full flex flex-row justify-end">
        <Button className="text-white" color="success" type="submit">
          {loading ? <Spinner color="white" /> : "Update"}
        </Button>
      </div>
    </form>
  );
};

export default ChangeBirthdate;
