import { Input, Button, Spinner } from "@nextui-org/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ErrorChip from "../../../Errors/ErrorChip";
import { useProfile } from "../../../../../context/ProfileContext";
import UsersService from "../../../../../services/UsersService";
import { MdError } from "react-icons/md";

const ChangeName = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const {
        userProfile,
        userProfileLoading,
        userProfileIsRefetching,
        refetchuserProfile
    } = useProfile();

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm();

    const handleChangeNameSubmit = (data) => {
        setLoading(true);
        toast
            .promise(
                () =>
                    UsersService.updateName({
                        firstname: data.firstname,
                        lastname: data.lastname,
                    }),
                {
                    pending: "Updating Names",
                    success: "Successfully saved",
                    error: "Something went wrong"
                },
                {
                    pauseOnFocusLoss: false
                }
            )
            .then(() => refetchuserProfile())
            .catch((e) => {
                setError(e.response.data.message);
            })
            .finally(() => setLoading(false))
    }

    return (
        userProfileLoading || userProfileIsRefetching ? (
            <div className="w-full h-32 flex items-center justify-center">
                <Spinner />
            </div>
        ) : userProfile?.firstname.length > 0 || userProfile?.lastname.length > 0 ? (
            <div className="rounded-lg w-full px-6 py-3 border-2 border-danger bg-danger/15">
                <div className="text-danger flex flex-row items-center space-x-3">
                    <MdError size={25} />
                    You can only set your name once
                </div>
            </div>
        ) : (
            <form onSubmit={handleSubmit(handleChangeNameSubmit)}>
                {!!error && (
                    <ErrorChip className={"mb-9"}>
                        {error}
                    </ErrorChip>
                )}
                <Input
                    {...register('firstname', { required: 'First Name must not be empty' })}
                    className="mb-9"
                    fullWidth
                    label="First Name"
                    labelPlacement="outside"
                    placeholder="First Name"
                    color={errors.firstname ? 'danger' : 'default'}
                    description={errors.firstname ? errors.firstname.message : ''}
                    defaultValue={userProfile?.firstname ?? ''}
                />
                <Input
                    {...register('lastname', { required: 'Last Name must not be empty' })}
                    fullWidth
                    className="mb-3"
                    label="Last Name"
                    labelPlacement="outside"
                    placeholder="Last Name"
                    color={errors.lastname ? 'danger' : 'default'}
                    description={errors.lastname ? errors.lastname.message : ''}
                    defaultValue={userProfile?.lastname ?? ''}
                />

                <div className="w-full flex flex-row justify-end">
                    <Button type="submit" color='success' className="text-white">
                        {loading ? (
                            <Spinner color="white" />
                        ) : (
                            <span>Update</span>
                        )}
                    </Button>
                </div>
            </form>
        )

    );
}

export default ChangeName;