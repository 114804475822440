import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import KycService from "../../../services/KycService";
import AdminService from "../../../services/AdminService";
import { useEffect } from "react";

export default function KycAdminView() {
  const { qr_hash } = useParams();

  const { data: kycDetails, isLoading } = useQuery({
    queryKey: ["kyc", "view", qr_hash],
    queryFn: () => KycService.view(qr_hash),
    enabled: !!qr_hash,
  });

  return (
    <>
      <span className="text-2xl font-bold">
        hello: {isLoading ? "loading..." : kycDetails.user_id}
      </span>
    </>
  );
}
