import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { bsc } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import { http, createConfig, WagmiProvider } from "wagmi";
import { walletConnect, injected, coinbaseWallet } from "wagmi/connectors";
import { NextUIProvider } from "@nextui-org/react";
import { ToastContainer, Bounce } from "react-toastify";
import ProfileContextProvider from "./context/ProfileContext";

const queryClient = new QueryClient();

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? "";

console.log("projectId: ", projectId);

const metadata = {
  name: "Connect Wallet",
  description: "Connect to your wallet",
  url: "https://dash.srtrevolution.com",
  icons: ["https://dash.srtrevolution.com/favicon.ico"],
};

const chains = [bsc];
const wagmiConfig = createConfig({
  chains,
  transports: {
    [bsc.id]: http(),
  },
  connectors: [
    walletConnect({ projectId, metadata, shoqQrModal: true }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0],
    }),
  ],
});

createWeb3Modal({
  wagmiConfig,
  projectId,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ThemeContext>
          <WagmiProvider config={wagmiConfig}>
            <QueryClientProvider client={queryClient}>
              <NextUIProvider>
                <ProfileContextProvider>
                  <SkeletonTheme highlightColor="#4b4b5c" baseColor="#2D2D37">
                    <ToastContainer
                      position="bottom-right"
                      autoClose={5000}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="dark"
                      transition={Bounce}
                    />
                    <App />
                  </SkeletonTheme>
                </ProfileContextProvider>
              </NextUIProvider>
            </QueryClientProvider>
          </WagmiProvider>
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
reportWebVitals();
