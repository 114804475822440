import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Button,
  Link,
  Chip,
  useDisclosure,
  Modal,
  Input,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Textarea,
  Card,
  Avatar,
  Spinner,
  User,
} from "@nextui-org/react";
import {
  FaAngleDoubleRight,
  FaArrowLeft,
  FaAngleDoubleLeft,
  FaKey,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService";
import Skeleton from "react-loading-skeleton";
import profileDefault from "../../../images/blank.webp";
import {
  formatHash,
  snakeToSpaced,
  imageSrcBuilder,
} from "../../helpers/stringHelpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import StakingRecords from "./StakingRecords";
import VerifyUserDownlines from "./VerifyUserDownlines";
import VerifyUserIndirectDownlines from "./VerifyUserIndirectDownlines";
import UserTransferHistory from "./UserTransferHistory";
import { CardBody, CardHeader } from "react-bootstrap";
import UserSkeleton from "./UserSkeleton";
import { Web3Service } from "../../../services/Web3Service";

const VerifyUser = () => {
  const { qr_hash } = useParams();
  const navigate = useNavigate();

  const [banLoading, setBanLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const {
    data: userActivationInfo,
    isLoading: userActivationInfoLoading,
    isRefetching: userActivationRefetching,
    refetch: refetchUserActivation,
  } = useQuery({
    queryKey: ["activationInfo", qr_hash],
    queryFn: () => AdminService.getUserActivationInfo({ qr_hash }),
    enabled: false,
  });

  const {
    isOpen: banIsOpen,
    onOpen: banOnOpen,
    onOpenChange: banOnOpenChange,
    onClose: banOnClose,
  } = useDisclosure();

  const {
    isOpen: resetPasswordIsOpen,
    onOpen: resetPasswordOnOpen,
    onOpenChange: resetPasswordOnOpenChange,
    onClose: resetPasswordOnClose,
  } = useDisclosure();

  const {
    isOpen: approveIsOpen,
    onOpen: approveOnOpen,
    onOpenChange: approveOnOpenChange,
    onClose: approveOnClose,
  } = useDisclosure();
  const queryClient = useQueryClient();

  const {
    data: verifyUserProfile,
    isLoading: verifyUserProfileLoading,
    refetch: refetchUserProfile,
    isRefetching: isRefetchingUserProfile,
  } = useQuery({
    queryKey: ["profile", qr_hash],
    queryFn: () =>
      AdminService.getUserProfile({
        qr_hash,
        skipErrorRedirect: true,
      }),
    enabled: !!qr_hash,
  });

  const {
    data: tokenBalance,
    isLoading: tokenBalanceLoading,
    isRefetching: tokenBalanceRefetching,
  } = useQuery({
    queryKey: ["verifyUserTokenBalance", verifyUserProfile?.walletAddress],
    queryFn: () =>
      Web3Service.getTokenBalance({
        address: verifyUserProfile?.walletAddress,
      }),
  });

  const {
    data: bnbBalance,
    isLoading: bnbBalanceLoading,
    isRefetching: bnbBalanceRefetching,
  } = useQuery({
    queryKey: ["verifyUserBnbBalance", verifyUserProfile?.walletAddress],
    queryFn: () =>
      Web3Service.getBnbBalance({
        address: verifyUserProfile?.walletAddress,
      }),
  });

  const {
    data: totalBuy,
    isLoading: totalBuyLoading,
    refetch: refetchTotalBuy,
    isRefetching: totalBuyRefetching,
  } = useQuery({
    queryKey: ["verifyUserTotalBuy", verifyUserProfile?.walletAddress],
    queryFn: () =>
      AdminService.getUserTotalBuy({
        wallet_address: verifyUserProfile?.walletAddress,
        skipErrorRedirect: true,
      }),
    enabled: !!verifyUserProfile?.walletAddress,
  });

  const {
    data: totalSell,
    isLoading: totalSellLoading,
    refetch: refetchTotalSell,
    isRefetching: totalSellRefetching,
  } = useQuery({
    queryKey: ["verifyUserTotalSell", verifyUserProfile?.walletAddress],
    queryFn: () =>
      AdminService.getUserTotalSell({
        wallet_address: verifyUserProfile?.walletAddress,
        skipErrorRedirect: true,
      }),
    enabled: !!verifyUserProfile?.walletAddress,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  // const [selectBtn, setSelectBtn] = useState("");

  const maxTableRows = 10;
  const currentPage = Math.max(1, searchParams.get("page")) || 1;
  const fastForwardInterval = 5;

  const {
    data: verifyUserSwaps,
    isLoading: verifyUserSwapsLoading,
    isRefetching: verifyUserSwapsRefetching,
    refetch: refetchVerifyUserSwaps,
  } = useQuery({
    queryKey: [
      "verifyUserSwaps",
      verifyUserProfile?.walletAddress,
      currentPage,
    ],
    queryFn: () =>
      AdminService.getUserSwaps({
        wallet_address: verifyUserProfile?.walletAddress,
        skipErrorRedirect: true,
        page: currentPage,
      }),
    refetchOnMount: true,
    enabled: !!verifyUserProfile?.walletAddress,
  });

  useEffect(() => {
    console.log("user swaps: ", verifyUserSwaps);
  }, [verifyUserSwaps]);

  useEffect(() => {
    refetchVerifyUserSwaps();
    refetchTotalBuy();
    refetchTotalSell();
    refetchUserActivation();
  }, [
    verifyUserProfile,
    refetchVerifyUserSwaps,
    refetchTotalBuy,
    refetchTotalSell,
    refetchUserActivation,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [qr_hash, queryClient]);

  const totalPages = Math.ceil(verifyUserSwaps?.total / maxTableRows);
  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 4) {
        for (let i = 1; i <= 5; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      } else if (currentPage > totalPages - 4) {
        pages.push(1);
        pages.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push("...");
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages);
      }
    }
    return pages;
  };

  useEffect(() => {
    refetchVerifyUserSwaps();
  }, [currentPage, refetchVerifyUserSwaps]);

  const handleApprove = async () => {
    setApproveLoading(true);
    toast
      .promise(
        AdminService.approveUser({
          qr_hash: verifyUserProfile?.qr_hash,
        }),
        {
          pending: "Approving user...",
          success: "User approved successfully",
          error: "Failed to approve user",
        }
      )
      .then(() => {
        approveOnClose();
        window.location.reload();
      })
      .finally(() => setApproveLoading(false));
  };

  const handleBan = async (data) => {
    setBanLoading(true);
    // const samplePromise = new Promise((resolve) => setTimeout(resolve, 1000));
    toast
      .promise(
        // samplePromise,
        AdminService.banUser({
          qr_hash: verifyUserProfile?.qr_hash,
          reason: data.banReason,
        }),
        {
          pending: "Banning user...",
          success: "User banned successfully",
          error: "Failed to ban user",
        }
      )
      .then(() => {
        banOnClose();
        window.location.reload();
      })
      .finally(() => setBanLoading(false));
  };

  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const handleChangePassword = async (data) => {
    setChangePasswordLoading(true);
    // const samplePromise = new Promise((resolve) => setTimeout(resolve, 1000));
    toast
      .promise(
        AdminService.updateUserPassword({
          qr_hash: verifyUserProfile?.qr_hash,
          new_password: data.new_password,
          repeat_password: data.repeat_password,
        }),
        {
          pending: "Changing password...",
          success: "Changed Password",
          error: "Failed to change password",
        }
      )
      .then(() => {
        resetPasswordOnClose();
      })
      .finally(() => {
        setChangePasswordLoading(false);
        reset();
      });
  };

  const [activationLoading, setActivationLoading] = useState(false);

  const handleManualUserActivation = () => {
    setActivationLoading(true);

    toast
      .promise(
        () =>
          AdminService.manualActivation({
            qr_hash: qr_hash,
          }),
        {
          pending: "Activating user...",
          success: "User activated successfully",
        }
      )
      .then(() => refetchUserActivation())
      .catch((e) => toast.error(e?.response?.data?.message))
      .finally(() => setActivationLoading(false));
  };

  const UserCard = () => {
    return (
      <Card className="p-6 flex flex-row items-center space-x-3">
        <Avatar src={profileDefault} />
        <div className="flex flex-col justify-center">
          <span className="font-semibold">{verifyUserProfile?.username}</span>
          <span className="text-sm">
            {snakeToSpaced(verifyUserProfile?.usergroup)}
          </span>
        </div>
      </Card>
    );
  };

  if (!qr_hash) {
    return (
      <div className="card lastest_trans">
        <div className="card-header border-0 flex-wrap">
          <Link
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft color="white" className="cursor-pointer scale-150" />
          </Link>
          <div>
            <h2 className="heading text-red-500">No User</h2>
          </div>
          <div className="d-flex align-items-center mb-3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        backdrop="blur"
        isOpen={resetPasswordIsOpen}
        onOpenChange={resetPasswordOnOpenChange}
        placement="center"
        className="z-20"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Reset Password
              </ModalHeader>
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <ModalBody>
                  <Input
                    {...register("new_password", {
                      required: true,
                    })}
                    autoFocus
                    endContent={
                      <FaKey className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                    }
                    label="New Password"
                    placeholder="Enter new password"
                    variant="bordered"
                  />
                  <Input
                    {...register("repeat_password", {
                      required: true,
                      validate: (value) => value === watch("new_password"),
                    })}
                    endContent={
                      <FaKey className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                    }
                    color={errors.repeat_password ? "danger" : "default"}
                    label="Repeat Password"
                    placeholder="Repeat Password"
                    variant="bordered"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button isDisabled={changePasswordLoading} type="submit">
                    Change Password
                  </Button>
                  <Button
                    isDisabled={changePasswordLoading}
                    color="danger"
                    onPress={() => {
                      onClose();
                      reset();
                    }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        backdrop="blur"
        isOpen={banIsOpen}
        onOpenChange={banOnOpenChange}
        placement="center"
        className="z-20"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <form onSubmit={handleSubmit(handleBan)}>
                <ModalHeader className="flex flex-col gap-1">
                  Ban User
                </ModalHeader>
                <ModalBody>
                  <span className="text-white font-semibold">
                    Are you sure you want to{" "}
                    <span className="text-red-500">ban</span> this user?
                  </span>
                  <UserCard />
                  <Textarea
                    {...register("banReason")}
                    autoFocus
                    label="Ban Reason"
                    placeholder="Enter ban reason here"
                    variant="bordered"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="submit"
                    color="danger"
                    variant="flat"
                    isLoading={banLoading}
                    isDisabled={banLoading}
                  >
                    Ban User
                  </Button>
                  <Button color="default" onPress={onClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        backdrop="blur"
        isOpen={approveIsOpen}
        onOpenChange={approveOnOpenChange}
        placement="center"
        className="z-20"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Approve User
              </ModalHeader>
              <ModalBody>
                <span className="text-white font-semibold">
                  Are you sure you want to{" "}
                  <span className="text-green-500">approve</span> this user?
                </span>
                <UserCard />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  variant="flat"
                  onPress={handleApprove}
                  isLoading={approveLoading}
                  isDisabled={approveLoading}
                >
                  Approve User
                </Button>
                <Button color="default" onPress={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="card-header border-0 flex flex-row justify-between">
        <div className="flex flex-row space-x-6">
          <Link
            onClick={() => {
              navigate(-1);
            }}
          >
            <FaArrowLeft color="white" className="cursor-pointer scale-150" />
          </Link>
          <div className="flex-row space-x-2 hidden sm:flex">
            <h2 className="heading">Back</h2>
          </div>
        </div>
        <div className="flex flex-row space-x-3">
          {verifyUserProfile?.is_verified === "no" &&
          !verifyUserProfileLoading &&
          !isRefetchingUserProfile ? (
            <>
              <Button variant="faded" color="success" onPress={approveOnOpen}>
                Approve User
              </Button>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      <Card className="z-[2]">
        <CardHeader className="flex flex-col sm:flex-row space-y-3 sm:space-y-0">
          {verifyUserProfileLoading || isRefetchingUserProfile ? (
            <UserSkeleton />
          ) : (
            <User
              name={
                verifyUserProfile?.firstname + " " + verifyUserProfile?.lastname
              }
              description={
                <div className="flex flex-col">
                  <span>@{verifyUserProfile?.username}</span>
                  <div className="flex flex-row space-x-1 mt-1">
                    <Chip
                      color={`${
                        verifyUserProfile?.status === "c"
                          ? "danger"
                          : verifyUserProfile?.is_verified === "yes"
                          ? "success"
                          : "default"
                      }`}
                      variant="flat"
                      size="sm"
                    >
                      {verifyUserProfile?.status === "c"
                        ? "Banned"
                        : verifyUserProfile?.is_verified === "yes"
                        ? "Verified"
                        : "Not Verified"}
                    </Chip>
                    <Chip
                      color={`${
                        verifyUserProfile?.usergroup === "yacht_club"
                          ? "primary"
                          : "success"
                      }`}
                      variant="flat"
                      size="sm"
                    >
                      {snakeToSpaced(verifyUserProfile?.usergroup)}
                    </Chip>
                  </div>
                </div>
              }
              avatarProps={{
                src: imageSrcBuilder(verifyUserProfile?.profile_pic),
                size: "lg",
              }}
            />
          )}

          <div className="flex flex-col justify-center space-y-2">
            <Button onPress={resetPasswordOnOpen}>Change Password</Button>
            {verifyUserProfileLoading || isRefetchingUserProfile ? (
              <Skeleton width={200} height={20} />
            ) : (
              verifyUserProfile?.status !== "c" && (
                <Button variant="faded" color="danger" onPress={banOnOpen}>
                  Ban User
                </Button>
              )
            )}
          </div>
        </CardHeader>
        <CardBody className="flex flex-col">
          <h4>User Info</h4>
          {verifyUserProfileLoading || isRefetchingUserProfile ? (
            <div className="space-y-3">
              <Skeleton className="w-3/5 rounded-lg">
                <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
              </Skeleton>
              <Skeleton className="w-4/5 rounded-lg">
                <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
              </Skeleton>
              <Skeleton className="w-2/5 rounded-lg">
                <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
              </Skeleton>
            </div>
          ) : (
            <div className="space-y-2 flex-col flex text-sm">
              <span className="text-gray-500">
                Email:
                <span className="text-white">
                  {" "}
                  {verifyUserProfile?.email ? verifyUserProfile?.email : "N/A"}
                </span>
              </span>
              <span className="text-gray-500">
                Wallet Address:
                <span className="text-white">
                  {" "}
                  {verifyUserProfile?.walletAddress
                    ? verifyUserProfile?.walletAddress
                    : "N/A"}
                </span>
              </span>
              <span className="text-gray-500">
                QR Hash:
                <span className="text-white">
                  {" "}
                  {verifyUserProfile?.qr_hash
                    ? verifyUserProfile?.qr_hash
                    : "N/A"}
                </span>
              </span>
            </div>
          )}
        </CardBody>
      </Card>

      <Card className="mb-6 p-6 pt-12 -mt-6 z-0 bg-gray-900">
        <h4 className="text-primary">Activation Info</h4>
        {userActivationInfoLoading || userActivationRefetching ? (
          <Skeleton width={100} height={10} count={4} />
        ) : (
          <>
            <span
              className={`${
                userActivationInfo?.is_activated === "y"
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {userActivationInfo?.is_activated === "y"
                ? "Activated"
                : "Not Activated"}
            </span>
            <span className="text-neutral">
              Min. Maintaining Balance:{" "}
              <span className="text-primary">
                {Intl.NumberFormat("en-US").format(
                  Number(userActivationInfo?.minimum_balance_to_maintain ?? 0)
                )}
              </span>
            </span>
            <span className="text-neutral">
              Tokens Purchased During Activation:{" "}
              <span className="text-primary">
                {Intl.NumberFormat("en-US").format(
                  Number(
                    userActivationInfo?.total_token_purchased_during_activation ??
                      0
                  )
                )}
              </span>
            </span>
            <span className="text-neutral">
              USD Value of Purchase:{" "}
              <span className="text-primary">
                {Intl.NumberFormat("en-US").format(
                  Number(userActivationInfo?.usd_value_of_purchase ?? 0)
                )}
              </span>
            </span>
            <span className="text-neutral">
              USD Value of Token:{" "}
              <span className="text-primary">
                {Number(userActivationInfo?.usd_value_of_token ?? 0)}
              </span>
            </span>
            {userActivationInfo?.is_activated !== "y" && (
              <Button
                onPress={handleManualUserActivation}
                color="success"
                variant="bordered"
                className="mt-3"
              >
                {activationLoading ? (
                  <Spinner color="white" />
                ) : (
                  "Activate User Manually"
                )}
              </Button>
            )}
          </>
        )}
      </Card>
      <div className="w-full flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-6 mb-3">
        <VerifyUserCard title={"Total Token"}>
          {tokenBalanceLoading || tokenBalanceRefetching ? (
            <Skeleton width={50} height={20} />
          ) : (
            <h2 color="heading">
              SRT&nbsp;
              {Intl.NumberFormat("en-US").format(
                Number(tokenBalance?.balance).toFixed(2)
              )}
            </h2>
          )}
        </VerifyUserCard>
        <VerifyUserCard title={"Total BNB"}>
          {bnbBalanceLoading || bnbBalanceRefetching ? (
            <Skeleton width={50} height={20} />
          ) : (
            <h2 color="heading">
              SRT&nbsp;
              {Intl.NumberFormat("en-US").format(
                Number(bnbBalance?.balance ?? 0).toFixed(2)
              )}
            </h2>
          )}
        </VerifyUserCard>
      </div>
      <div className="w-full flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-6">
        <VerifyUserCard title={"Total Buy"}>
          {totalBuyLoading || totalBuyRefetching ? (
            <Skeleton width={50} height={20} />
          ) : (
            <h2 color="heading">
              SRT&nbsp;
              {Intl.NumberFormat("en-US").format(
                Number(totalBuy?.total_token_bought ?? 0).toFixed(2)
              )}
            </h2>
          )}
        </VerifyUserCard>
        <VerifyUserCard title={"Total Sell"}>
          {totalSellLoading || totalSellRefetching ? (
            <Skeleton width={50} height={20} />
          ) : (
            <h2 color="heading">
              SRT&nbsp;
              {Intl.NumberFormat("en-US").format(
                Number(totalSell?.total_token_sold).toFixed(2)
              )}
            </h2>
          )}
        </VerifyUserCard>
      </div>
      <div className="w-full mt-6">
        <VerifyUserCard title={"User Swaps"}>
          {verifyUserSwapsLoading || verifyUserSwapsRefetching ? (
            <Skeleton count={5} height={50} className="my-1" />
          ) : verifyUserSwaps?.data?.length <= 0 ? (
            <p>There are currently no swaps</p>
          ) : (
            <div className="overflow-x-scroll">
              <table className="table border-no tbl-btn short-one">
                <thead>
                  <tr>
                    <th>Block Time</th>
                    <th>txn hash</th>
                    <th>Swap Type</th>
                    <th>From Amount</th>
                    <th>From Symbol</th>
                    <th>To Amount</th>
                    <th>To Symbol</th>
                    <th>USD Value</th>
                  </tr>
                </thead>
                <tbody>
                  {verifyUserSwaps?.data.map((data, index) => (
                    <tr
                      className={`hover:bg-gray-800 cursor-pointer trans-td-list dark:!text-white ${
                        index % 2 === 0 ? "bg-gray-900" : ""
                      }`}
                      key={index}
                    >
                      <td>
                        {new Date(data.blockTime).toLocaleString("en-US", {
                          month: "short",
                          year: "numeric",
                          day: "2-digit",
                        })}
                      </td>
                      <td>
                        <Link
                          href={`https://bscscan.com/tx/${data.txn_hash}`}
                          target="_blank"
                        >
                          {formatHash(data.txn_hash)}
                        </Link>
                      </td>
                      <td
                        className={`
                          ${
                            data.swap_type === "buy"
                              ? "!text-green-500"
                              : "!text-red-500"
                          }`}
                      >
                        {data.swap_type}
                      </td>
                      <td>
                        {Intl.NumberFormat("en-US").format(
                          Number(data.from_amount).toFixed(4)
                        )}
                      </td>
                      <td>{data.from_symbol}</td>
                      <td>
                        {Intl.NumberFormat("en-US").format(
                          Number(data.to_amount).toFixed(4)
                        )}
                      </td>
                      <td>{data.to_symbol}</td>
                      <td>{data.token_usd_value}</td>
                    </tr>
                  ))}{" "}
                </tbody>
              </table>
            </div>
          )}
          <div className="table-pagenation overflow-x-scroll">
            {verifyUserSwapsLoading ? (
              <Skeleton height={25} width={150} />
            ) : (
              <p>
                Showing{" "}
                <span>
                  {(currentPage - 1) * maxTableRows + 1} -{" "}
                  {(currentPage - 1) * maxTableRows + maxTableRows}
                </span>
                from <span>{verifyUserSwaps?.total}</span>users
              </p>
            )}

            {verifyUserSwapsLoading ? (
              <Skeleton width={150} height={20} />
            ) : (
              <nav>
                <ul className="pagination pagination-gutter pagination-primary no-bg">
                  <li className="page-item page-indicator">
                    <div
                      className="page-link"
                      onClick={() =>
                        setSearchParams({
                          page: Number(currentPage) - 1,
                        })
                      }
                    >
                      <i className="fa-solid fa-angle-left"></i>
                    </div>
                  </li>
                  {currentPage > 1 && (
                    <li className="page-item page-indicator">
                      <div
                        className="page-link"
                        onClick={() =>
                          setSearchParams({
                            page: Math.max(
                              1,
                              Number(currentPage) - fastForwardInterval
                            ),
                          })
                        }
                      >
                        <FaAngleDoubleLeft />
                      </div>
                    </li>
                  )}
                  {getPageNumbers().map((number) => (
                    <li
                      key={number}
                      className={`page-item ${
                        number === currentPage ? "active" : ""
                      }`}
                    >
                      <div
                        className="page-link"
                        onClick={() =>
                          setSearchParams({
                            page: number,
                          })
                        }
                      >
                        {number}
                      </div>
                    </li>
                  ))}
                  {currentPage < totalPages && (
                    <li className="page-item page-indicator">
                      <div
                        className="page-link"
                        onClick={() =>
                          setSearchParams({
                            page: Math.min(
                              totalPages,
                              Number(currentPage) + fastForwardInterval
                            ),
                          })
                        }
                      >
                        <FaAngleDoubleRight />
                      </div>
                    </li>
                  )}
                  <li className="page-item page-indicator">
                    <div
                      className="page-link"
                      onClick={() =>
                        setSearchParams({
                          page: Number(currentPage) + 1,
                        })
                      }
                    >
                      <i className="fa-solid fa-angle-right"></i>
                    </div>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </VerifyUserCard>
      </div>
      <StakingRecords qr_hash={qr_hash} />
      <VerifyUserDownlines qr_hash={qr_hash} />
      <VerifyUserIndirectDownlines qr_hash={qr_hash} />
      <UserTransferHistory qr_hash={qr_hash} />
    </>
  );
};

export const VerifyUserCard = ({ children, title, className }) => {
  return (
    <div className={`rounded-xl !bg-cardbg flex-grow p-6 ${className}`}>
      <h2 className="text-lg font-semibold text-white">{title}</h2>
      {children}
    </div>
  );
};

export default VerifyUser;
