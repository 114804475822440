import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useEffect, useState, useMemo, useRef } from "react";
import AdminService from "../../../services/AdminService";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Tooltip,
  Spinner,
  Button,
} from "@nextui-org/react";
import { useCallback } from "react";
import { formatHash } from "../../helpers/stringHelpers";
import ClipboardJS from "clipboard";
import { toast } from "react-toastify";
import { VerifyUserCard } from "./VerifyUser";

const UserTransferHistory = ({ qr_hash }) => {
  const [page, setPage] = useState(1);

  const {
    data: transferHistory,
    isLoading: transferHistoryLoading,
    isPlaceholderData,
  } = useQuery({
    queryKey: ["userTransferHistory", qr_hash, page],
    queryFn: () => AdminService.getTransferHistory({ qr_hash, page }),
    placeholderData: keepPreviousData,
  });

  const [stablePages, setStablePages] = useState(null);

  const pages = useMemo(() => {
    return transferHistory?.last_page;
  }, [transferHistory]);

  useEffect(() => {
    if (pages) {
      setStablePages(pages);
    }
  }, [pages]);

  useEffect(() => {
    console.log("transfer history: ", transferHistory);
  }, [transferHistory]);

  const handleCopy = useCallback((e) => {
    const clipboard = new ClipboardJS(e.target, {
      text: () => e.target.getAttribute("data-copy-text"),
    });

    clipboard.on("success", () => {
      toast.success("Copied to clipboard");
    });

    return () => clipboard.destroy();
  }, []);

  const renderCell = useCallback((item, columnKey) => {
    const cellValue = item[columnKey];

    switch (columnKey) {
      case "amount":
        return (
          item["symbol"] +
          " " +
          Intl.NumberFormat("en-US").format(Number(cellValue))
        );

      case "timestamp":
        return new Date(cellValue * 1000).toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

      case "from_wallet_address":
      case "to_wallet_address":
      case "txn_hash":
        return (
          <Tooltip content={cellValue}>
            <Button
              className="text-blue-500 underline cursor-pointer"
              onPress={handleCopy}
              data-copy-text={cellValue}
            >
              {formatHash(cellValue)}
            </Button>
          </Tooltip>
        );

      case "from_username":
      case "to_username":
        if (!cellValue) {
          return "N/A";
        }
        return cellValue;

      default:
        return cellValue;
    }
  }, [handleCopy]);

  const loadingState =
    isPlaceholderData || transferHistoryLoading ? "loading" : "idle";

  return (
    <>
      <VerifyUserCard title="Transfer History" className={"mt-6"}>
        <Table
          title="Transfer History"
          isStriped
          aria-label="User Transfer History"
          bottomContent={
            pages > 0 ? (
              <div className="flex w-full justify-center">
                <Pagination
                  showControls
                  showShadow
                  color="primary"
                  page={page}
                  total={stablePages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            ) : null
          }
        >
          <TableHeader>
            <TableColumn key="amount">Amount</TableColumn>
            <TableColumn key="timestamp">Date</TableColumn>
            <TableColumn key="from_username">From</TableColumn>
            <TableColumn key="from_wallet_address">From Address</TableColumn>
            <TableColumn key="to_username">To</TableColumn>
            <TableColumn key="to_wallet_address">To Address</TableColumn>
            <TableColumn key="txn_hash">Txn Hash</TableColumn>
          </TableHeader>
          <TableBody
            items={transferHistory?.data ?? []}
            loadingContent={<Spinner />}
            loadingState={loadingState}
          >
            {(item) => (
              <TableRow className="h-16" key={item?.txn_hash}>
                {(columnKey) => (
                  <TableCell>{renderCell(item, columnKey)}</TableCell>
                )}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </VerifyUserCard>
    </>
  );
};

export default UserTransferHistory;
