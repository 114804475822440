import {
  TableCell,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableRow,
  User,
  Chip,
} from "@nextui-org/react";
import { snakeToSpaced, imageSrcBuilder } from "../../helpers/stringHelpers";
import { useNavigate } from "react-router-dom";

const ControlPanelTable = ({
  data,
  bottomContent,
  loadingContent,
  loadingState,
  removeWrapper = false,
}) => {
  const navigate = useNavigate();
  const renderCell = (item, columnKey) => {
    switch (columnKey) {
      case "name":
        return (
          <User
            avatarProps={{
              radius: "lg",
              src: imageSrcBuilder(item?.profile_pic),
              fallback: item?.firstname?.charAt(0).toUpperCase(),
              alt: "avatar",
            }}
            description={item.email}
            name={item.firstname + " " + item.lastname}
          >
            {item.username}
          </User>
        );
      case "usergroup":
        return snakeToSpaced(item.usergroup);
      case "status":
        return (
          <Chip
            className="capitalize"
            color={item.status === "o" ? "success" : "danger"}
            size="sm"
            variant="flat"
          >
            {item.status === "o"
              ? "Active"
              : item.status === "c"
              ? "banned"
              : "Inactive"}
          </Chip>
        );
      case "time_created":
        return new Date(item.time_created * 1000).toLocaleString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
      default:
        return null;
    }
  };

  return (
    <Table
      isStriped
      fullWidth
      removeWrapper={removeWrapper}
      selectionMode="single"
      onRowAction={(qr_hash) => navigate(`/users-to-verify/${qr_hash}`)}
      // bottomContent={bottomContent}
      className="overflow-scroll"
    >
      <TableHeader>
        <TableColumn key={"name"}>Name</TableColumn>
        <TableColumn key={"usergroup"}>Usergroup</TableColumn>
        <TableColumn key={"status"}>Status</TableColumn>
        <TableColumn key={"time_created"}>Time Created</TableColumn>
      </TableHeader>
      <TableBody
        items={data}
        loadingContent={loadingContent}
        loadingState={loadingState}
      >
        {(item) => (
          <TableRow key={item.qr_hash} className="cursor-pointer">
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ControlPanelTable;
