import { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { useAccount, useDisconnect } from "wagmi";
import UsersService from "../../../services/UsersService";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../../../src/context/ProfileContext";
import { toast } from "react-toastify";

export default function ConnectWallet() {
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();
  const { address, isConnected } = useAccount();
  const [savingWallet, setSavingWallet] = useState(false);

  const { userProfile, userProfileLoading } = useProfile();

  useEffect(() => {
    if (userProfile?.walletAddress) {
      // window.location.replace("/");
      navigate("/");
    }
  }, [userProfile, navigate]);

  const handleConnectWallet = async () => {
    setSavingWallet(true);

    // const samplePromise = new Promise((resolve) => setTimeout(() => resolve(), 3000));

    toast
      .promise(() =>
        // samplePromise,
        UsersService.updateWalletAddress({ walletAddress: address, skipErrorRedirect: true }),
        {
          pending: "Saving Wallet Address...",
          success: "Wallet Address Saved",
          error: "Failed to save Wallet Address"
        },
      )
      .then(() => {
        console.log("Wallet Address Saved");
        // window.location.reload();
        window.location.replace("/");
      })
      .catch((e) => {
        console.log(e.response.data.message);
        toast.error(e.response.data.message);
      })
      .finally(() => {
        setSavingWallet(false);
      });
  }

  if (userProfileLoading || savingWallet) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[100vh] flex flex-col justify-center items-center space-y-4">
      {isConnected && !!address && (
        <span className="text-center">Wallet Address: {address}</span>
      )}
      <w3m-button></w3m-button>
      {isConnected && (
        <>
          <span className="text-green-500">Is this the correct Address?</span>
          <Button onClick={handleConnectWallet} size="lg" className="text-white" color="success">Yes, proceed to my Dashboard</Button>
        </>
      )}
      {isConnected && (
        <Button size="lg" onClick={(() => disconnect())} color="danger">No, connect another Wallet</Button>
      )}
    </div>
  );
}
