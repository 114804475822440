import { useState, useEffect, useCallback } from "react";
import { Button } from "@nextui-org/react";
import { useWeb3Modal, useWeb3ModalEvents } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import UsersService from "../../../services/UsersService";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { useProfile } from "../../../../src/context/ProfileContext";

export default function Buy() {
  const { open } = useWeb3Modal();
  const { connectors, disconnectAsync } = useDisconnect();
  const [hasDisconnected, setHasDisconnected] = useState(false);
  const [disconnectAttempted, setDisconnectAttempted] = useState(false);
  const navigate = useNavigate();
  const { data: modalEvent } = useWeb3ModalEvents();
  const { address } = useAccount();
  const [savingWallet, setSavingWallet] = useState(false);
  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get("r")) {
      localStorage.setItem("referral_code", params.get("r"));
    }
  }, [params]);

  const disconnectAll = useCallback(async () => {
    console.log("disconnecting");
    for (const connector of connectors) {
      try {
        await disconnectAsync(
          { connector },
          {
            onSuccess: () => {
              console.log(`${connector.name} disconnected successfully`);
            },
            onError: (error) =>
              console.error(`Failed to disconnect ${connector.name}:`, error),
          },
        );
      } catch (error) {
        console.error(
          `Error during disconnect process for ${connector.name}:`,
          error,
        );
      }
    }
    setHasDisconnected(true);
  }, [connectors, disconnectAsync]);

  useEffect(() => {
    if (!disconnectAttempted) {
      disconnectAll();
      setDisconnectAttempted(true);
    }
  }, [disconnectAll, disconnectAttempted]);

  useEffect(() => {
    if (
      (modalEvent.event === "CONNECT_SUCCESS" ||
        modalEvent.event === "MODAL_CLOSE") &&
      address
    ) {
      console.log("saving wallet");
      console.log("address", address);
      console.log("referral_code", params.get("r") || "");
      setSavingWallet(true);
      UsersService.purchaseWalletUpdate({
        wallet_address: address,
        referral_code: params.get("r") || "",
        skipErrorRedirect: true,
      })
        .then(() => {
          window.location.replace(
            "https://pancakeswap.finance/swap?outputCurrency=0xe177295Fa0142331aBDc8B106832789e0c0fE77b",
          );
        })
        .catch((error) => {
          // swal("Error", error.message, "error");
          console.log("something went wrong: ", error);
        })
        .finally(() => setSavingWallet(false));
    }
  }, [modalEvent, address, navigate, params]);

  useEffect(() => {
    console.log("modal event: ", modalEvent.event);
    console.log("params: ", params.get("r"));
    console.log("address: ", address);
  }, [modalEvent, params, address]);

  if (savingWallet) {
    return (
      <div className="w-full h-[100vh] flex justify-center items-center">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <Button onClick={() => open()}>Connect Wallet</Button>
    </div>
  );
}
