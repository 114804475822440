import React, { useState, useRef } from "react";

import {
  Avatar,
  Card,
  CardBody,
  Tabs,
  Tab,
  Badge,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  Button,
} from "@nextui-org/react";
import { useProfile } from "../../../../context/ProfileContext";
import { imageSrcBuilder, snakeToSpaced } from "../../../helpers/stringHelpers";
import Skeleton from "react-loading-skeleton";
import { FaCamera } from "react-icons/fa";
import ChangeName from "./Tabs/ChangeName";
import ChangePassword2 from "./Tabs/ChangePassword2";
import ChangeBirthdate from "./Tabs/ChangeBirthdate";
import { motion } from "framer-motion";
import UsersService from "../../../../services/UsersService";
import { toast } from "react-toastify";

const EditProfile = () => {
  const fileInputRef = useRef(null);
  const [hover, setHover] = useState(false);
  const [uploading, setUploading] = useState(false);

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const { userProfile, userProfileLoading, refetchuserProfile } = useProfile();

  const handleAvatarClick = () => {
    if (!uploading) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file) => {
    setUploading(true);
    toast
      .promise(() => UsersService.uploadProfilePic({ profile_pic: file }), {
        pending: "Uploading Profile Picture...",
        success: "Profile Picture Uploaded",
      })
      .then(() => {
        onOpen();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setUploading(false));
  };

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement="center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>Successfully Uploaded Profile Picture</ModalHeader>
              <ModalFooter>
                <Button
                  onPress={() => {
                    refetchuserProfile();
                    onClose();
                  }}
                  color="success"
                  className="text-white"
                >
                  Proceed
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="w-full flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-3 items-center md:items-stretch">
        <Card className="w-full md:w-1/3 min-h-[40vh]">
          <CardBody className="flex justify-center items-center">
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange} // Define this function to handle file changes
            />
            <Badge
              content={<FaCamera className="m-2" />}
              color="primary"
              size="md"
              placement="bottom-right"
            >
              <motion.div
                className="relative w-32 h-32 cursor-pointer"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={handleAvatarClick}
              >
                <Avatar
                  className={`w-full h-full ${
                    hover ? "opacity-45" : ""
                  } duration-300 cursor-pointer mb-4`}
                  src={imageSrcBuilder(userProfile?.profile_pic)}
                  radius="full"
                />
                {hover && (
                  <motion.div
                    className="absolute inset-0 flex justify-center items-center text-white bg-black bg-opacity-50 rounded-full"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    Upload Photo
                  </motion.div>
                )}
              </motion.div>
            </Badge>
            {userProfileLoading ? (
              <Skeleton count={3} width={150} height={15} />
            ) : (
              <>
                <span className="font-bold text-xl mt-3">
                  {userProfile?.firstname}&nbsp;{userProfile?.lastname}
                </span>
                <span className="text-neutral-500 mb-2">
                  @{userProfile?.username}
                </span>
                <span className="">
                  {snakeToSpaced(userProfile?.usergroup)}
                </span>
              </>
            )}
          </CardBody>
        </Card>
        <Card className="flex-grow flex-1 w-full md:w-max">
          <div className="px-6 pt-6">User Settings</div>
          <CardBody>
            <div className="flex w-full flex-col">
              <Tabs
                aria-label="Options"
                size="sm"
                color="primary"
                variant="underlined"
                classNames={{
                  tabList:
                    "gap-6 w-full relative rounded-none p-0 border-b border-divider",
                  cursor: "w-full bg-link",
                  tab: "max-w-fit px-0 h-12",
                  tabContent: "group-data-[selected=true]:text-link",
                }}
              >
                <Tab
                  key="changeName"
                  title={
                    <div className="flex items-center space-x-2">
                      <span>Change Name</span>
                    </div>
                  }
                >
                  <ChangeName />
                </Tab>
                <Tab
                  key="change-bdate"
                  title={
                    <div className="flex items-center space-x-2">
                      <span>Change Birthdate</span>
                    </div>
                  }
                >
                  <ChangeBirthdate />
                </Tab>
                <Tab
                  key="change-password"
                  title={
                    <div className="flex items-center space-x-2">
                      <span>Change Password</span>
                    </div>
                  }
                >
                  <ChangePassword2 />
                </Tab>
              </Tabs>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
export default EditProfile;
