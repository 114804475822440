import axiosInstance from "./AxiosInstance";

export default class BonusService {
  static async getSentCommunityBonus() {
    const bonusResponse = axiosInstance.get("/bonus/sent-community-bonus");

    return (await bonusResponse).data;
  }

  static async getDirectCommunityBonusThisMonth() {
    const bonusResponse = axiosInstance.get(
      "/bonus/sent-direct-community-bonus-this-month",
    );

    return (await bonusResponse).data.total_sent_bonus_this_month;
  }

  static async getDirectCommunityBonus() {
    const bonusResponse = axiosInstance.get(
      "/bonus/sent-direct-community-bonus",
    );

    return (await bonusResponse).data.total_sent_direct_bonus;
  }

  static async getDirectCommunityBonusList() {
    const listResponse = axiosInstance.get(
      "/bonus/direct-community-bonus-list",
    );

    return (await listResponse).data;
  }

  static async getIndirectCommunityBonusList({ limit, page }) {
    const listResponse = axiosInstance.get("/bonus/indirect-bonus-list", {
      params: {
        limit,
        page,
      },
    });

    return (await listResponse).data;
  }

  static async getDirectCommunityDailySeries(from_time, to_time) {
    const seriesResponse = axiosInstance.get(
      "/bonus/sent-direct-community-bonus-daily-series",
      {
        params: {
          from_time,
          to_time,
        },
      },
    );

    return (await seriesResponse).data;
  }

  static async getIndirectBonusTotal(period = "") {
    const bonusResponse = axiosInstance.get("/bonus/indirect-bonus-total", {
      params: {
        period,
      },
    });

    return (await bonusResponse).data.total_indirect_bonus;
  }
}
