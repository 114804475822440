import { Button, Input, Spinner } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ErrorChip from "../../../Errors/ErrorChip";
import { toast } from "react-toastify";
import UsersService from "../../../../../services/UsersService";

const ChangePassword2 = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset
    } = useForm();

    useEffect(() => {
        if (loading) {
            setError('');
        }
    }, [loading]);

    const handleChangePasswordSubmit = (data) => {
        setLoading(true);
        toast
            .promise(
                () =>
                    UsersService.changePassword({
                        old_password: data.old_password,
                        new_password: data.new_password,
                        repeat_password: data.new_password_confirmation
                    }),
                {
                    pending: "Changing Password",
                    success: "Successfully Changed Password",
                    error: "Something went wrong"
                }
            )
            .then(() => reset())
            .catch((e) => {
                setError(e.response.data.message);
            })
            .finally(
                () =>
                    setLoading(false)
            )
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleChangePasswordSubmit)} className="space-y-9">
                {!!error && (
                    <ErrorChip>
                        {error}
                    </ErrorChip>
                )}
                <Input
                    {...register('old_password', { required: 'Old Password must not be empty' })}
                    fullWidth
                    type="password"
                    label="Old Password"
                    labelPlacement="outside"
                    placeholder="Old Password"
                    color={errors.old_password ? 'danger' : 'default'}
                    description={errors.old_password ? errors.old_password.message : ''}
                />
                <Input
                    {...register('new_password', { required: 'New Password must not be empty' })}
                    fullWidth
                    type="password"
                    label="New Password"
                    labelPlacement="outside"
                    placeholder="New Password"
                    color={errors.new_password ? 'danger' : 'default'}
                    description={errors.new_password ? errors.new_password.message : ''}
                />
                <Input
                    {...register('new_password_confirmation', {
                        required: 'New Password Confirmation must not be empty',
                        validate: (value) => value === watch('new_password') || 'Passwords must match'
                    })}
                    fullWidth
                    type="password"
                    label="Confirm Password"
                    labelPlacement="outside"
                    placeholder="Confirm Password"
                    color={errors.new_password_confirmation ? 'danger' : 'default'}
                    description={errors.new_password_confirmation ? errors.new_password_confirmation.message : ''}
                />

                <div className="w-full flex flex-row justify-end">
                    <Button type="submit" color="success" className="text-white">
                        {
                            loading ? (
                                <Spinner color="white" />
                            ) : "Update"
                        }
                    </Button>
                </div>

            </form></>

    );
}

export default ChangePassword2;