import axiosInstance from "./AxiosInstance";

export default class UsersService {
  static async getProfile({ skipErrorRedirect = false }) {
    const profileResponse = axiosInstance.get("/user/profile", {
      skipErrorRedirect,
    });

    return (await profileResponse).data;
  }

  static async getSwaps() {
    const swapsResponse = axiosInstance.get("/user/swaps");

    return (await swapsResponse).data;
  }

  static async changePassword({ old_password, new_password, repeat_password }) {
    const changePasswordResponse = axiosInstance.post(
      "/user/change-password",
      {
        old_password,
        new_password,
        repeat_password,
      },
      { skipErrorRedirect: true },
    );

    return (await changePasswordResponse).data;
  }

  static async updateWalletAddress({
    walletAddress,
    skipErrorRedirect = false,
  }) {
    const updateWalletAddressResponse = axiosInstance.post(
      "/user/update-wallet-address",
      {
        walletAddress,
      },
      { skipErrorRedirect },
    );

    return (await updateWalletAddressResponse).data;
  }

  static async register(data) {
    try {
      const registerResponse = await axiosInstance.post("/register", data, {
        skipErrorRedirect: true,
      });
      return registerResponse.data;
    } catch (e) {
      throw e.response.data;
    }
  }

  static async purchaseWalletUpdate({
    referral_code,
    wallet_address,
    skipErrorRedirect = false,
  }) {
    const walletResponse = await axiosInstance.post(
      "/purchase/wallet-update",
      {
        referral_code,
        wallet_address,
      },
      { skipErrorRedirect },
    );

    return walletResponse.data;
  }

  static async getActivationInfo() {
    const activationInfoResponse = await axiosInstance.get(
      "/user/activation-info",
    );

    return activationInfoResponse.data;
  }

  static async updateName({ firstname, lastname }) {
    const nameResponse = await axiosInstance.post(
      "/user/update-name",
      {
        firstname,
        lastname
      }
    );

    return nameResponse.data;
  }

  static async updateBirthdate({ birthdate }) {
    const birthdateResponse = await axiosInstance.post(
      "/user/update-birthdate",
      {
        birthdate
      }
    );

    return birthdateResponse.data;
  }

  static async uploadProfilePic({ profile_pic }) {
    const formData = new FormData();
    formData.append("profile_pic", profile_pic);

    const uploadResponse = await axiosInstance.post(
      "/user/upload-profile-pic",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return uploadResponse.data;
  }
}
