import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SRT_API_URL}`,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).token
    : null;
  config.params = config.params || {};
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.config.skipErrorRedirect && error.response.status === 401) {
      localStorage.removeItem("userDetails");
      window.location.replace("/login");
    }

    if (error.response.status === 500) {
      error.message = "Something went wrong";
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
