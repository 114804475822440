import blank from "../../images/blank.webp";

export function snakeToSpaced(str) {
  if (str) {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  return "";
}

export function formatHash(hash, startLength = 6, endLength = 4) {
  return `${hash.slice(0, startLength)}...${hash.slice(-endLength)}`;
}

export function formatPhoneNumber(phone_number) {
  if (phone_number) {
    return phone_number.replace(/^0+|\s+|-/g, "");
  }

  return "";
}

export function imageSrcBuilder(profile_pic) {
  const imagePrefix = process.env.REACT_APP_IMAGE_PREFIX;
  // if profile_pic is a link, return it
  if (profile_pic && profile_pic.startsWith("http")) {
    return profile_pic;
  } else if (profile_pic) {
    return imagePrefix + profile_pic + ".jpg";
  } else {
    return blank;
  }
}
