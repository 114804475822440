import axiosInstance from './AxiosInstance';

export default class AdminService {
	static async getUsersToVerify({ page = 1, limit = 10 }) {
		const usersResponse = axiosInstance.get('/admin/user-to-verify-list', {
			params: {
				page,
				limit,
			},
		});

		return (await usersResponse).data;
	}

	static async getUserProfile({ qr_hash, skipErrorRedirect = true }) {
		const userResponse = axiosInstance.get(`/admin/user-profile`, {
			params: {
				qr_hash,
			},
			skipErrorRedirect,
		});

		return (await userResponse).data;
	}

	static async getUserTotalBuy({
		wallet_address,
		skipErrorRedirect = false,
	}) {
		const totalBuyResponse = axiosInstance.get(
			`/admin/view-total-bought-tokens`,
			{
				params: {
					wallet_address,
				},
				skipErrorRedirect,
			},
		);

		return (await totalBuyResponse).data;
	}

	static async getUserTotalSell({
		wallet_address,
		skipErrorRedirect = false,
	}) {
		const totalSellResponse = axiosInstance.get(
			`/admin/view-total-sold-tokens`,
			{
				params: {
					wallet_address,
				},
				skipErrorRedirect,
			},
		);

		return (await totalSellResponse).data;
	}

	static async getUserSwaps({
		page,
		wallet_address,
		skipErrorRedirect = false,
	}) {
		const userResponse = axiosInstance.get('/admin/view-user-swaps', {
			params: {
				wallet_address,
				page,
			},
			skipErrorRedirect,
		});

		return (await userResponse).data;
	}

	static async getStakingRecords({ qr_hash, skipErrorRedirect = false }) {
		const recordResponse = axiosInstance.get('/admin/get-staking-records', {
			params: {
				qr_hash,
			},
			skipErrorRedirect,
		});

		return (await recordResponse).data;
	}

	static async banUser({ qr_hash, reason }) {
		const banResponse = await axiosInstance.post('/admin/ban-user', {
			qr_hash,
			reason,
		});

		return banResponse.data;
	}

	static async approveUser({ qr_hash }) {
		const approveResponse = await axiosInstance.post(
			'/admin/approve-user',
			{
				qr_hash,
			},
		);

		return approveResponse.data;
	}

	static async getUserActivationInfo({ qr_hash }) {
		const activationResponse = await axiosInstance.get(
			'/admin/get-user-activation-info',
			{
				params: {
					qr_hash,
				},
			},
		);

		return activationResponse.data;
	}

	static async searchUsers({ query }) {
		const searchResponse = await axiosInstance.get('/admin/search-users', {
			params: {
				query,
			},
		});

		return searchResponse.data;
	}

	static async getDownlineUsers({ qr_hash }) {
		const downlineResponse = await axiosInstance.get(
			'/admin/get-downline-users',
			{
				params: {
					qr_hash,
				},
			},
		);

		return downlineResponse.data;
	}

	static async getIndirectDownlineUsers({ qr_hash }) {
		const downlineResponse = await axiosInstance.get(
			'/admin/get-indirect-downline-users',
			{ params: { qr_hash } },
		);

		return downlineResponse.data;
	}

	static async getTransferHistory({ qr_hash, page }) {
		const transferHistoryResponse = await axiosInstance.get(
			'/admin/get-transfer-history',
			{ params: { qr_hash, page } },
		);

		return transferHistoryResponse.data;
	}

	static async updateUserPassword({
		qr_hash,
		new_password,
		repeat_password,
	}) {
		const updateResponse = await axiosInstance.post(
			'/admin/update-user-password',
			{
				qr_hash,
				new_password,
				repeat_password,
			},
		);

		return updateResponse.data;
	}

	static async getUsers({ page, limit = 10 }) {
		const usersResponse = await axiosInstance.get('/admin/get-users', {
			params: {
				page,
				limit,
			},
		});

		return usersResponse.data;
	}

	static async manualActivation({ qr_hash }) {
		const activationResponse = await axiosInstance.post(
			'/admin/manual-activation',
			{
				qr_hash,
			},
		);

		return activationResponse.data;
	}
}
