import React, { useContext, useState, useEffect } from "react";

/// React router dom
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Nav2 from "./layouts/nav/index2";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
// import Main from "./layouts/Main";
import WalletBar from "./layouts/WalletBar";
/// Dashboard
// import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import ReferralBonus from "./components/Dashboard/ReferralBonus";
import CommunityBonus from "./components/Dashboard/CommunityBonus";
// import Ticketing from "./components/Dashboard/Ticketing";
// import Crypto from "./components/Dashboard/Crypto";
// import DashInvoice from "./components/Dashboard/DashInvoice";
// import Kanban from "./components/Dashboard/Kanban";

/////Demo
// import Theme1 from "./components/Dashboard/Demo/Theme1";
// import Theme2 from "./components/Dashboard/Demo/Theme2";
// import Theme3 from "./components/Dashboard/Demo/Theme3";
// import Theme4 from "./components/Dashboard/Demo/Theme4";
// import Theme5 from "./components/Dashboard/Demo/Theme5";
// import Theme6 from "./components/Dashboard/Demo/Theme6";

//Course
// import CourseList from "./components/Dashboard/CourseList";
// import CourseDetails from "./components/Dashboard/CourseDetails";

/// File Manager
// import FileManager from "./components/FileManager/FileManager";
// import User from "./components/FileManager/User";
// import HomeCalendar from "./components/FileManager/HomeCalendar";
// import Activity from "./components/FileManager/Activity";
// import ToDoList from "./components/FileManager/ToDoList";
// import FileChat from "./components/FileManager/FileChat";

//CMS
// import Content from "./components/Cms/Content";
// import Menu from "./components/Cms/Menu";
// import EmailTemplate from "./components/Cms/EmailTemplate";
// import Blog from "./components/Cms/Blog";
//CMS Linking Pages
// import ContentAdd from "./components/Cms/ContentAdd";
// import AddMail from "./components/Cms/AddMail";
// import AddBlog from "./components/Cms/AddBlog";
// import BlogCategory from "./components/Cms/BlogCategory";

/// App
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
// import Compose from "./components/AppsMenu/Email/Compose/Compose";
// import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
// import Read from "./components/AppsMenu/Email/Read/Read";
// import Calendar from "./components/AppsMenu/Calendar/Calendar";
// import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

// /// Product List
// import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
// import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
// import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
// import Customers from "./components/AppsMenu/Shop/Customers/Customers";

// /// Charts
// import SparklineChart from "./components/charts/Sparkline";
// import ChartJs from "./components/charts/Chartjs";
// import RechartJs from "./components/charts/rechart";
// import ApexChart from "./components/charts/apexcharts";

// /// Bootstrap
// import UiAlert from "./components/bootstrap/Alert";
// import UiAccordion from "./components/bootstrap/Accordion";
// import UiBadge from "./components/bootstrap/Badge";
// import UiButton from "./components/bootstrap/Button";
// import UiModal from "./components/bootstrap/Modal";
// import UiButtonGroup from "./components/bootstrap/ButtonGroup";
// import UiListGroup from "./components/bootstrap/ListGroup";
// import UiCards from "./components/bootstrap/Cards";
// import UiCarousel from "./components/bootstrap/Carousel";
// import UiDropDown from "./components/bootstrap/DropDown";
// import UiPopOver from "./components/bootstrap/PopOver";
// import UiProgressBar from "./components/bootstrap/ProgressBar";
// import UiTab from "./components/bootstrap/Tab";
// import UiPagination from "./components/bootstrap/Pagination";
// import UiGrid from "./components/bootstrap/Grid";
// import UiTypography from "./components/bootstrap/Typography";

// /// Plugins
// import Select2 from "./components/PluginsMenu/Select2/Select2";
// import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
// import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
// import Toastr from "./components/PluginsMenu/Toastr/Toastr";
// import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
// import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

// /// Widget
// import Widget from "./pages/Widget";

// /// Table
// import SortingTable from "./components/table/SortingTable/SortingTable";
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import SwapHistoryTable from "./components/table/SwapHistoryTable";

// /// Form
// import Element from "./components/Forms/Element/Element";
// import Wizard from "./components/Forms/Wizard/Wizard";
// import CkEditor from "./components/Forms/CkEditor/CkEditor";
// import Pickers from "./components/Forms/Pickers/Pickers";
// import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
// import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
// import Community from "./components/Dashboard/Community";
// import Ticketing2 from "./components/Dashboard/Ticketing2";
// import Login from "./pages/Login";
import CommunityList from "./components/Dashboard/CommunityList";
import ConnectWallet from "./components/Dashboard/ConnectWallet";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import UsersService from "../services/UsersService";
import UsersToVerify from "./components/Dashboard/UsersToVerify";
import VerifyUser from "./components/Dashboard/VerifyUser";
import { useProfile } from "../context/ProfileContext";
import Buy from "./components/Dashboard/Buy";
import Staking from "./components/Dashboard/Staking";
import ControlPanel from "./components/Dashboard/ControlPanel";
import KycAdmin from "./components/Dashboard/KycAdmin";
import KycAdminView from "./components/Dashboard/KycAdminView";
import KycAdd from "./components/Dashboard/KycAdd";
import KycReviewStatus from "./components/Dashboard/KycReviewStatus";
import YachtCodes from "./components/Dashboard/YachtCodes";

const Markup = () => {
  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("you're in login: ", location.pathname === '/login');
  //   if (location.pathname === '/login') {
  //     navigate('/connect-wallet');
  //   }
  // }, [navigate, location]);
  const navigate = useNavigate();
  const location = useLocation();

  const { userProfile, profileLoading, profileRefetching } = useProfile();

  useEffect(() => {
    if (
      !userProfile?.walletAddress &&
      location.pathname !== "/connect-wallet" &&
      userProfile &&
      !profileLoading &&
      !profileRefetching
    ) {
      navigate("/connect-wallet");
    }
  }, [userProfile, navigate, location, profileLoading, profileRefetching]);

  // if (profileLoading) {
  //   return (
  //     <div className="w-full h-[100vh] flex justify-center items-center">
  //       <div className="spinner-border text-light" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      <Routes>
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/connect-wallet" exact element={<ConnectWallet />} />
        <Route path="/buy" element={<Buy />} />
        <Route
          element={
            <Layout1
              userProfile={userProfile}
              userProfileLoading={profileLoading}
            />
          }
        >
          <Route path="/" exact element={<DashboardDark />} />
          {/* <Route path="/dashboard" exact element={<Home />} /> */}
          <Route path="/staking" exact element={<Staking />} />
          <Route path="/dashboard-dark" exact element={<DashboardDark />} />
          <Route path="/users-to-verify" exact element={<UsersToVerify />} />
          <Route path="/users-to-verify/:qr_hash" element={<VerifyUser />} />
          <Route path="/control-panel" element={<ControlPanel />} />
          <Route path="/settings" exact element={<EditProfile />} />
          <Route path="/kyc-admin" exact element={<KycAdmin />} />
          <Route path="/kyc-admin/:qr_hash" element={<KycAdminView />} />
          <Route path="/kyc-add" element={<KycAdd />} />
          <Route path="/kyc-review-status" element={<KycReviewStatus />} />
          <Route path="/yacht-codes" element={<YachtCodes />} />
          {/* <Route path="/header-theme" exact element={<Theme1 />} /> */}
          {/* <Route path="/sidebar-compact" exact element={<Theme3 />} /> */}
          {/* <Route path="/horizontal-sidebar" exact element={<Theme4 />} /> */}
          {/* <Route path="/horizontal-theme" exact element={<Theme5 />} /> */}
          {/* <Route path="/sidebar-mini" exact element={<Theme6 />} /> */}
          <Route path="/swap-history" exact element={<SwapHistoryTable />} />
          <Route path="/community" exact element={<CommunityList />} />
          <Route path="/community-bonus" exact element={<CommunityBonus />} />
          <Route path="/referral-bonus" exact element={<ReferralBonus />} />
        </Route>
      </Routes>
      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

function Layout1({ userProfile, userProfileLoading }) {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`wallet-open active show ${
        sidebariconHover ? "iconhover-toggle" : ""
      } ${menuToggle ? "menu-toggle" : ""}`}
    >
      <Nav2 userProfile={userProfile} userProfileLoading={userProfileLoading} />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height + 20 }}
      >
        <div className="container-fluid">
          <Outlet />
          <Footer />
        </div>
      </div>
      <WalletBar />
      <div className="wallet-bar-close"></div>
    </div>
  );
}

function Layout2() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height + 20 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer changeFooter="out-footer" />
    </div>
  );
}

function Layout3() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height + 20 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function Layout4() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div className="content-body">
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function Layout5() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div className="content-body message-body mh-auto">
        <div className="container-fluid mh-auto p-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
function Layout6() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div className="content-body">
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function Layout7() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height + 20 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Markup;
