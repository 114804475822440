import axiosInstance from "./AxiosInstance";

export default class MarketService {
  static async getLatestTokenPrice() {
    const tokenPriceResponse = await axiosInstance.get(
      "/market/latest-token-price"
    );

    return tokenPriceResponse.data;
  }

  static async getLatestBnbPrice() {
    const bnbPriceResponse = await axiosInstance.get(
      "/market/latest-bnb-price"
    );

    return bnbPriceResponse.data;
  }

  static async getTokenSeries(from_time, to_time) {
    const seriesResponse = await axiosInstance.get("/market/token-price", {
      params: {
        from_time,
        to_time,
      },
    });

    return seriesResponse.data;
  }

  static async getBnbSeries(from_time, to_time) {
    const seriesResponse = await axiosInstance.get("/market/bnb-price", {
      params: {
        from_time,
        to_time,
      },
    });

    return seriesResponse.data;
  }
}
