import { useEffect, useState, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Pagination, Input, Button, Spinner, Card } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import AdminService from "../../../services/AdminService";
import { toast } from "react-toastify";
import ControlPanelTable from "./ControlPanelTable";
import { FaArrowLeft } from "react-icons/fa";
import { CardBody } from "react-bootstrap";
import { useQuery, keepPreviousData } from "@tanstack/react-query";

const ControlPanel = () => {
  const [page, setPage] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const {
    data: userList,
    isLoading: usersLoading,
    isPlaceholderData,
  } = useQuery({
    queryKey: ["userList", page],
    queryFn: () => AdminService.getUsers({ page }),
    placeholderData: keepPreviousData,
  });

  const [stablePages, setStablePages] = useState(null);

  const pages = useMemo(() => {
    return userList?.last_page;
  }, [userList]);

  useEffect(() => {
    if (pages) {
      setStablePages(pages);
    }
  }, [pages]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    reset();
  }, [currentStep, reset]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSearch = (data) => {
    setLoading(true);
    AdminService.searchUsers({
      query: data.searchUser,
    })
      .then((response) => {
        setSearchResults(response);
        nextStep();
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const loadingState = isPlaceholderData || usersLoading ? "loading" : "idle";

  return (
    <div className="w-full flex items-center justify-center flex-col space-y-3">
      {usersLoading ? (
        <Spinner />
      ) : (
        <>
          <form onSubmit={handleSubmit(handleSearch)} className="w-full">
            <AnimatePresence mode="wait">
              {currentStep === 1 && (
                <Card fullWidth>
                  <CardBody>
                    <motion.div
                      key={"search-user"}
                      className="flex flex-col space-y-4"
                      initial={{ x: -300, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: -300, opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <span className="text-xl font-bold text-white">
                        Search User
                      </span>
                      <Input
                        {...register("searchUser", {
                          required: "This field is required",
                        })}
                        fullWidth
                        placeholder="Search user"
                        color={errors.searchUser ? "danger" : "default"}
                        description={
                          errors.searchUser ? errors.searchUser.message : ""
                        }
                      />
                      <Button
                        type="submit"
                        fullWidth
                        className="text-white"
                        color="success"
                      >
                        {loading ? <Spinner color="white" /> : "Search"}
                      </Button>
                      {!!userList?.data?.length && (
                        <>
                          <ControlPanelTable
                            data={userList.data}
                            removeWrapper
                            loadingContent={<Spinner />}
                            loadingState={loadingState}
                          />
                          {pages > 0 ? (
                            <div className="flex w-full justify-center">
                              <Pagination
                                showControls
                                showShadow
                                color="primary"
                                page={page}
                                total={stablePages}
                                onChange={(page) => setPage(page)}
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                    </motion.div>
                  </CardBody>
                </Card>
              )}
              {currentStep === 2 && (
                <motion.div
                  key={"user-list"}
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 300, opacity: 0 }}
                  transition={{
                    type: "tween",
                    duration: 0.5,
                  }}
                >
                  <Button onPress={prevStep} className="mb-6">
                    <FaArrowLeft />
                  </Button>
                  <ControlPanelTable data={searchResults} />
                </motion.div>
              )}
            </AnimatePresence>
          </form>
        </>
      )}
    </div>
  );
};

export default ControlPanel;
