import { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Input,
  Button,
  Card,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { motion, AnimatePresence } from "framer-motion";
import { FaArrowLeft } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthService from "../../services/AuthService2";
import { useEffect } from "react";
import ErrorChip from "../components/Errors/ErrorChip";

const steps = [
  { id: 1, title: "Enter Details" },
  { id: 2, title: "Verify OTP" },
];

const ResetPassword = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otpRequestToken, setOtpRequestToken] = useState(null);
  const navigate = useNavigate();

  const { onOpen, onOpenChange, isOpen } = useDisclosure();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (loading) {
      setError(null);
    }
  }, [loading, currentStep]);

  useEffect(() => {
    reset();
  }, [currentStep, reset]);

  const nextStep = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    }
  };

  const backStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleDetailsSubmit = (data) => {
    console.log("data: ", data);
    // const samplePromise = new Promise(resolve => setTimeout(() => resolve(), 1000));
    setLoading(true);
    toast
      .promise(
        () =>
          AuthService.requestPasswordReset({
            emailOrPhone: data.emailOrPhone,
            username: data.username,
          }),
        {
          pending: "Sending OTP...",
          success: "OTP Sent",
          error: "Failed to send OTP",
        },
        {
          pauseOnFocusLoss: false,
        },
      )
      .then((data) => {
        setOtpRequestToken(data.otp_request_token);
        nextStep();
      })
      .catch((e) => {
        setError(e.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOtpSubmit = (data) => {
    setLoading(true);
    toast
      .promise(
        () =>
          AuthService.verifyPasswordReset({
            otp_code: data.otp_code,
            new_password: data.new_password,
            new_password_confirmation: data.new_password_confirmation,
            otp_request_token: otpRequestToken,
          }),
        {
          pending: "Verifying OTP...",
          success: "OTP Verified",
          error: "Failed to verify OTP",
        },
        {
          pauseOnFocusLoss: false,
        },
      )
      .then(() => {
        onOpen();
      })
      .catch((e) => {
        setError(e.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        isDismissable={false}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader>Successfully Changed Password</ModalHeader>
              <ModalFooter>
                <Button
                  className="text-white"
                  color="success"
                  onPress={() => navigate("/login")}
                >
                  Login
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-center">
          <div className="col-xl-8">
            <FaArrowLeft
              color="white"
              className="cursor-pointer scale-150 mb-8"
              onClick={() => navigate(-1)}
            />
            <AnimatePresence mode="wait">
              <div className="card overflow-hidden">
                {currentStep === 1 && (
                  <motion.div
                    key={"details"}
                    initial={{ x: -300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -300, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="card-body p-4 flex flex-col">
                      <h1 className="flex-grow">{steps[0].title}</h1>
                      {!!error && (
                        <ErrorChip>
                          {typeof error !== "string"
                            ? error.errors?.email
                              ? error.errors?.email[0]
                              : error.errors?.contact
                                ? error.errors?.contact[0]
                                : error.message
                                  ? error.message
                                  : JSON.stringify(error)
                            : error}
                        </ErrorChip>
                      )}
                      <form
                        onSubmit={handleSubmit(handleDetailsSubmit)}
                        className="flex flex-col space-y-4 flex-grow"
                      >
                        <Input
                          {...register("username", {
                            required: "Username is required",
                          })}
                          fullWidth
                          label="Username"
                          placeholder="Username"
                          labelPlacement="outside"
                          className="mb-8"
                          color={errors.username ? "danger" : "default"}
                          description={
                            errors.username ? errors.username.message : ""
                          }
                        />
                        <Input
                          {...register("emailOrPhone", {
                            required: "Email / Phone Number is required",
                          })}
                          fullWidth
                          label="Email or Phone Number"
                          placeholder="Email or Phone Number"
                          labelPlacement="outside"
                          color={errors.emailOrPhone ? "danger" : "default"}
                          description={
                            errors.emailOrPhone
                              ? errors.emailOrPhone.message
                              : ""
                          }
                        />
                        <Button type="submit">
                          {loading ? <Spinner color="white" /> : "Submit"}
                        </Button>
                      </form>
                    </div>
                  </motion.div>
                )}
                {currentStep === 2 && (
                  <motion.div
                    key={"otp"}
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 300, opacity: 0 }}
                    transition={{ type: "tween", duration: 0.5 }}
                  >
                    <div className="card-body p-4 flex flex-col">
                      <div className="flex flex-col items-start flex-grow">
                        <Button isIconOnly onPress={backStep} className="mb-8">
                          <FaArrowLeft
                            color="white"
                            className="cursor-pointer scale-150"
                          />
                        </Button>
                        <h1>{steps[1].title}</h1>
                        {!!error && (
                          <Card className="flex-grow bg-warning-300 py-1 px-3 mb-6 flex flex-row items-center space-x-4">
                            <MdError size={20} />
                            <span>
                              {typeof error !== "string"
                                ? error.errors?.email
                                  ? error.errors?.email[0]
                                  : error.errors?.contact
                                    ? error.errors?.contact[0]
                                    : error.message
                                      ? error.message
                                      : JSON.stringify(error)
                                : error}
                            </span>
                          </Card>
                        )}
                      </div>
                      <form
                        onSubmit={handleSubmit(handleOtpSubmit)}
                        className="flex flex-col space-y-4 flex-grow"
                      >
                        <Input
                          {...register("new_password", {
                            required: "Password is required",
                          })}
                          fullWidth
                          label="New Password"
                          placeholder="Enter New Password"
                          labelPlacement="outside"
                          type="password"
                          className="mb-8"
                          color={errors.new_password ? "danger" : "default"}
                          description={
                            errors.new_password
                              ? errors.new_password.message
                              : ""
                          }
                        />
                        <Input
                          {...register("new_password_confirmation", {
                            required: "Password is required",
                            validate: (value) =>
                              value === watch("new_password") ||
                              "Passwords do not match",
                          })}
                          fullWidth
                          label="Repeat Password"
                          placeholder="Enter Repeat Password"
                          labelPlacement="outside"
                          type="password"
                          className="!mb-8"
                          color={
                            errors.new_password_confirmation
                              ? "danger"
                              : "default"
                          }
                          description={
                            errors.new_password_confirmation
                              ? errors.new_password_confirmation.message
                              : ""
                          }
                        />
                        <Input
                          {...register("otp_code", {
                            required: "OTP is required",
                          })}
                          fullWidth
                          label="Enter OTP"
                          placeholder="*****"
                          labelPlacement="outside"
                          color={errors.otp_code ? "danger" : "default"}
                          description={
                            errors.otp_code ? errors.otp_code.message : ""
                          }
                        />
                        <Button type="submit">
                          {loading ? <Spinner color="white" /> : "Submit"}
                        </Button>
                      </form>
                    </div>
                  </motion.div>
                )}
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
